// actions.js

import axiosInstance from "../context/axios";
import {
  FETCH_DATA_ROYAL_FAMILY_REQUEST,
  FETCH_DATA_ROYAL_FAMILY_SUCCESS,
  FETCH_DATA_ROYAL_FAMILY_FAILURE,

  FETCH_DATA_POST_REQUEST,
  FETCH_DATA_POST_SUCCESS,
  FETCH_DATA_POST_FAILURE,

  FETCH_DATA_POST_SEARCH_REQUEST,
  FETCH_DATA_POST_SEARCH_SUCCESS,
  FETCH_DATA_POST_SEARCH_FAILURE,

  FETCH_DATA_BMD_REQUEST,
  FETCH_DATA_BMD_SUCCESS,
  FETCH_DATA_BMD_FAILURE,

} from "./actionTypes";


export const resetFilters = () => ({
  type: 'RESET_FILTERS',
});


export const setLanguage = (languageCode) => {
  return {
    type: 'SET_LANGUAGE',
    payload: languageCode,
  };
};


export const setCurrentPage = (page) => {
  return{
    type: "SET_CURRENT_POST_PAGE",
    payload: page,
  }
};

export const setCurrentBMDPage = (page) => {
  return{
    type: "SET_CURRENT_BMD_PAGE",
    payload: page,
  }
};

export const setCurrentSearchPostPage = (page) => {
  return {
    type: "SET_CURRENT_SEARCH_POST_PAGE",
    payload: page,
  }
};

export const setCurrentRoyalFamilyPage = (page) => {
  return {
    type: "SET_CURRENT_ROYALFAMILY_PAGE",
    payload: page,
  }
};



// Method function Royal family
export const fetchDataRoyalFamilyRequest = () => ({
  type: FETCH_DATA_ROYAL_FAMILY_REQUEST,
});
export const fetchDataRoyalFamilySuccess = (dataResponse, currentPage) => {
  const data = dataResponse.results
  return {
    type: FETCH_DATA_ROYAL_FAMILY_SUCCESS,
    payload: {data, currentPage},
}
};
export const fetchDataRoyalFamilyFailure = (error) => ({
  type: FETCH_DATA_ROYAL_FAMILY_FAILURE,
  payload: error,
});


// Method function Posts
export const fetchDataPostsRequest = (postType) => ({
  type: FETCH_DATA_POST_REQUEST,
  payload: { postType }
});
export const fetchDataPostsSuccess = (postType, data, currentPage, filters) => ({
  type: FETCH_DATA_POST_SUCCESS,
  payload: { postType, data, currentPage, filters }
});
export const fetchDataPostsFailure = (postType, error) => ({
  type: FETCH_DATA_POST_FAILURE,
  payload: { postType, error }
});


// Method function Posts Search Items
export const fetchDataPostsSearchRequest = () => ({
  type: FETCH_DATA_POST_SEARCH_REQUEST,
});
export const fetchDataPostsSearchSuccess = (dataResponse, currentPage, filters) => {
  const data = dataResponse.results;
  return {
    type: FETCH_DATA_POST_SEARCH_SUCCESS,
    payload: { data, currentPage, filters },
  };
};
export const fetchDataPostsSearchFailure = (error) => ({
  type: FETCH_DATA_POST_SEARCH_FAILURE,
  payload: error,
});


// Method Funcion BMD
export const fetchDataBMDRequest = () => ({
  type: FETCH_DATA_BMD_REQUEST,
});
export const fetchDataBMDSuccess = (dataResponse, currentPage, filters) => {
  const data = dataResponse.results
  return {
    type: FETCH_DATA_BMD_SUCCESS,
    payload: {data, currentPage, filters},
  }
};
export const fetchDataBMDFailure = (error) => ({
  type: FETCH_DATA_BMD_FAILURE,
  payload: error,
});


// GET POST ROYAL FAMILY
export const fetchDataRoyalFamily = (languageCode, currentPage) => {
  return async (dispatch) => {
    try {
      await dispatch(fetchDataRoyalFamilyRequest());
      const response = await axiosInstance.get(`${languageCode}/api/royal-family/?page=${currentPage}`);
      await dispatch(fetchDataRoyalFamilySuccess(response.data, currentPage));
      return response;
    } catch (error) {
      await dispatch(fetchDataRoyalFamilyFailure(error.message));
    }  
  };
};


// GET POST DATAS MAIN CATEGORY
export const fetchDataPostMainCategory = (
  languageCode,
  postType,
  slugValue = null,
  day = null,
  month = null,
  tagIds = null,
  year = null,
  currentPage = 1,
  navigate = null
) => {
  const filters = {
    category: slugValue,
    day,
    month,
    tag_id: tagIds,
    year,
    page: currentPage,
  };

  // Remove null or undefined values from the filters object
  const filteredFilters = Object.fromEntries(
    Object.entries(filters).filter(([_, value]) => value !== null && value !== undefined)
  );

  const queryString = new URLSearchParams(filteredFilters).toString();
  const url = `${languageCode}/api/post/posts/?${queryString}`;

  return async (dispatch) => {
    try {
      await dispatch(fetchDataPostsRequest(postType));
      const response = await axiosInstance.get(url);
      await dispatch(fetchDataPostsSuccess(postType, response.data, currentPage, filters));
      if (navigate) {
        navigate(`./?${queryString}`);
      }
      return response; // Return the axios response for chaining promises
    } catch (error) {
      await dispatch(fetchDataPostsFailure(postType, error.message));
      throw error; // Re-throw the error to propagate it to the calling function
    }
  };
};


// GET POST DATAS NOT NEWS
export const fetchDataPost = (postType, languageCode, currentPage, filters) => {
  return async (dispatch) => {
    try {
      await dispatch(fetchDataPostsRequest(postType));
      const response = await axiosInstance.get(`${languageCode}/api/post/hot-news/?page=${currentPage}`);

      await dispatch(fetchDataPostsSuccess(postType, response.data, currentPage, filters));
      return response;
    } catch (error) {
      await dispatch(fetchDataPostsFailure(postType, error.message));
    }
  };
};


// GET POST DATAS CATEGORY
export const fetchDataPostByCategory = (
  languageCode,
  postType,
  slugValue = null, 
  day = null, 
  month = null, 
  tagIds = null, 
  year = null, 
  currentPage = 1, 
  navigate = null,
) => {
  const filters = {
    category: slugValue,
    day,
    month,
    tag_id: tagIds,
    year,
    page : currentPage,
  };

  // Remove null or undefined values from the filters object
  const filteredFilters = Object.fromEntries(
    Object.entries(filters).filter(([_, value]) => value !== null && value !== undefined)
  );
  const queryString = new URLSearchParams(filteredFilters).toString();
  return async (dispatch) => {
    try {
      await dispatch(fetchDataPostsRequest(postType));
      const response = await axiosInstance.get(`${languageCode}/api/post/posts/?${queryString}`);
      await dispatch(fetchDataPostsSuccess(postType, response.data, currentPage, filters));
      if (navigate) {
        navigate(`./?${queryString}`);
      }
      return response;
    } catch (error) {
      await dispatch(fetchDataPostsFailure(postType, error.message));
      throw error;  // Re-throw the error to propagate it to the calling function
    }
  };
};



// GET POST DATAS By Filter Multi Options
export const fetchDataPostsFilter = (
  languageCode,
  postType,
  slugValue = null, 
  day = null, 
  month = null, 
  tagIds = null, 
  year = null, 
  currentPage = 1, 
  navigate = null
) => {
  
  const filters = {
    category: slugValue,
    day,
    month,
    year,
    tag_id: tagIds,
    page: currentPage
  };

  // Remove null or undefined values from the filters object
  const filteredFilters = Object.fromEntries(
    Object.entries(filters).filter(([_, value]) => value !== null && value !== undefined)
  );

  const queryString = new URLSearchParams(filteredFilters).toString();
  const url = `${languageCode}/api/post/posts/?${queryString}`;

  return async (dispatch) => {
    try {
      await dispatch(fetchDataPostsRequest(postType));
      const response = await axiosInstance.get(url);
      await dispatch(fetchDataPostsSuccess(postType, response.data, currentPage, filters));
      if (navigate) {
        navigate(`./?${queryString}`);
      }
      return response;
    } catch (error) {
      await dispatch(fetchDataPostsFailure(postType, error.message));
      throw error;  // Re-throw the error to propagate it to the calling function
    }
  };
};



// GET SEARCH POST DATAS
export const fetchDataSearchPost = (
  languageCode,
  currentPage = 1,
  formSearch,
  start_date=null,
  end_date=null,
  tag_id=null,
  navigate=null,
  seletedCategory=null
) => {

  const slugCategory = seletedCategory ? seletedCategory.toLowerCase().replace(/\s+/g, '-') : '';
  const filters = {
    name:formSearch,
    category: slugCategory,
    start_date,
    end_date,
    tag_id,
    page: currentPage
  };
  // Remove null or undefined values from the filters object
  const filteredFilters = Object.fromEntries(
    Object.entries(filters).filter(([_, value]) => value !== null && value !== undefined)
  );
  const queryString = new URLSearchParams(filteredFilters).toString();
  return async (dispatch) => {
    try {
      await dispatch(fetchDataPostsSearchRequest());
      const response = await axiosInstance.get(`${languageCode}/api/post/search-results/?${queryString}`);
      await dispatch(fetchDataPostsSearchSuccess(response.data, currentPage, filters));
      if (navigate) {
        navigate(`/search-results/?${queryString}`);
      }
      return response;
    } catch (error) {
      await dispatch(fetchDataPostsSearchFailure(error.message));
      throw error;  // Re-throw the error to propagate it to the calling function
    }
  };
};



// GET POST BMD DATAS
export const fetchDataBMD =  (
  languageCode, 
  slugValue = null, 
  day = null, 
  month = null, 
  tagIds = null, 
  year = null, 
  currentPage = 1,
  navigate = null

) => {
  const filters = {
    category: slugValue,
    day,
    month,
    tag_id: tagIds,
    year,
    page: currentPage
  };
  // Remove null or undefined values from the filters object
  const filteredFilters = Object.fromEntries(
    Object.entries(filters).filter(([_, value]) => value !== null && value !== undefined)
  );
  // Convert the filtered filters to a query string
  const queryString = new URLSearchParams(filteredFilters).toString();

  return async (dispatch) => {
    try {
      await dispatch(fetchDataBMDRequest());
      const response = await axiosInstance.get(`${languageCode}/api/post/bmd/?${queryString}`)
      await dispatch(fetchDataBMDSuccess(response.data, currentPage, filters));
      return response;
    } catch (error) {
      await dispatch(fetchDataBMDFailure(error.message));
    }
  };
};


export const fetchDataBMDFilter = (
  languageCode, 
  slugValue = null, 
  day = null, 
  month = null, 
  tagIds = null, 
  year = null, 
  currentPage = 1, 
  navigate = null

) => {
  const filters = {
    category: slugValue,
    day,
    month,
    tag_id: tagIds,
    year,
    page: currentPage
  };
  // Remove null or undefined values from the filters object
  const filteredFilters = Object.fromEntries(
    Object.entries(filters).filter(([_, value]) => value !== null && value !== undefined)
  );
  // Convert the filtered filters to a query string
  const queryString = new URLSearchParams(filteredFilters).toString();
  return async (dispatch) => {
    try {
      await dispatch(fetchDataBMDRequest());
      const response = await axiosInstance.get(`${languageCode}/api/post/bmd/?${queryString}`);
      await dispatch(fetchDataBMDSuccess(response.data, currentPage, filters));
      if(navigate){
        navigate(`./?${queryString}`);
      }
      return response;
    } catch (error) {
      await dispatch(fetchDataBMDFailure(error.message));
      throw error;  // Re-throw the error to propagate it to the calling function
    }
  };
};



// GET POST EARCH TAGS_ID
export const fetchDataTagPost = (
  languageCode, 
  postType,
  tagId,
  currentPage = 1,
  navigate, 
  {}
  ) => {
    const filters = {
      page: currentPage,
      tag_ids: tagId,
    };
    const filteredFilters = Object.fromEntries(
      Object.entries(filters).filter(([_, value]) => value !== null && value !== undefined)
    );
    const queryString = new URLSearchParams(filteredFilters).toString();

  return async (dispatch) => {
    try {
      await dispatch(fetchDataPostsRequest(postType));
      const response = await axiosInstance.get(`${languageCode}/api/post/list-by-tag/?${queryString}`);

      await dispatch(fetchDataPostsSuccess(postType, response.data, currentPage, filters));
      if (navigate) {
        navigate(`/tags?${queryString}`);
      }
      return response;
    } catch (error) {
      await dispatch(fetchDataPostsFailure(postType, error.message));
    }
  };
};
