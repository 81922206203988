import React, { useState, useEffect } from "react";
import { Tab, Tabs, ListGroup, Image } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { IoCloseCircleSharp, IoPlayCircleOutline } from "react-icons/io5";
import { MdDownloadForOffline } from "react-icons/md";
import ReactPlayer from "react-player";
import PlayerApp from "../../components/audioPlayer";

export default function OtherDetail({ dataPostDetail }) {

  const { t } = useTranslation();
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [selectedVideoIndex, setSelectedVideoIndex] = useState(null);
  const dataParagraph = dataPostDetail.description;

  const openModal = (index) => {
    setModalIsOpen(true);
    setSelectedVideoIndex(index);
  };

  const closeModal = () => {
    setModalIsOpen(false);
    setSelectedVideoIndex(null);
  };

  const handleDownload = async (event, videoId) => {
    event.stopPropagation(); // Prevent the click event from triggering the parent item click

    try {
      // Make an HTTP request to the Django view that serves the video file
      const response = await fetch(`/download-video/${videoId.video_ids}/`);
      const blob = await response.blob();

      // Create a download link
      const downloadLink = document.createElement("a");
      downloadLink.href = URL.createObjectURL(blob);
      downloadLink.target = "_blank";
      downloadLink.download = `video_${videoId.name}.mp4`; // Set the desired file name

      // Append the link to the document and trigger a click event
      document.body.appendChild(downloadLink);
      downloadLink.click();

      // Remove the link from the document
      document.body.removeChild(downloadLink);
    } catch (error) {
      console.error("Error downloading video:", error);
    }
  };

  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.key === "Escape" && modalIsOpen) {
        event.preventDefault();
        closeModal();
      }
    };

    window.addEventListener("keydown", handleKeyDown);

    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, [modalIsOpen]);


  return (
    <div className="pre-scrollables">
      <div className="content-body-media-post detial-media-dialog">
        <Tabs
          defaultActiveKey={
            dataPostDetail.video_ids.length > 0 ? "video" : "filmographie" ||
              dataPostDetail.file_mp3.length > 0 ? "Music" : "filmographie"
          }
          id="myTabs"
          className="custom-tabs"
        >
          {dataPostDetail?.video_ids.length > 0 ? (
            <Tab eventKey="video" title={t("video")}>
              <ListGroup className="video-list">
                {dataPostDetail.video_ids &&
                  dataPostDetail.video_ids.length > 0 &&
                  dataPostDetail.video_ids.map((resVideosID, index) => {
                    // const thumbnailUrl = `https://img.youtube.com/vi/${resVideosID.video_ids}/0.jpg`;
                    const thumbnailUrl = `${resVideosID.image}`;

                    return (
                      <React.Fragment key={index}>
                        <ListGroup.Item
                          onClick={() => openModal(index)}
                          className="video-list-item"
                        >
                          <div className="video-image">
                            <Image
                              src={thumbnailUrl}
                              alt={`YouTube Video - ${resVideosID.video_ids}`}
                              rounded
                              className="full-width-thumbnail"
                            />
                            <IoPlayCircleOutline
                              size={60}
                              color="#fff"
                              className="play-icon"
                            />
                          </div>
                          <div className="video-name">
                            <h4>{resVideosID.name}</h4>

                            <div><MdDownloadForOffline
                              onClick={(e) => handleDownload(e, resVideosID)}
                              color="#032ea1"
                              size={25}
                            /></div>
                          </div>
                        </ListGroup.Item>
                      </React.Fragment>
                    );
                  })}
              </ListGroup>
            </Tab>
          ) : null}

          {dataParagraph !== null ? (
            <Tab eventKey="filmographie" title={t("filmography")}>
              <div
                style={{ paddingRight: 15 }}
                dangerouslySetInnerHTML={{ __html: dataParagraph }}
              />
            </Tab>
          ) : null}

          {dataPostDetail?.file_mp3.length > 0 ? (
            <Tab eventKey="Music" title={t("music")}>
              <PlayerApp dataPostDetail={dataPostDetail} />
            </Tab>) : null}

        </Tabs>
        {modalIsOpen && selectedVideoIndex !== null ? (
          <div className="modal-overlay" onClick={closeModal}>
            <div className="modal-content">
              <IoCloseCircleSharp
                color="#fff"
                size={50}
                className="close-icon"
                onClick={closeModal}
              />
              <div className="video-play">
                <ReactPlayer
                  url={
                    dataPostDetail.video_ids[selectedVideoIndex]?.video_ids ?
                      `https://www.youtube.com/watch?v=${dataPostDetail.video_ids[selectedVideoIndex]?.video_ids}`
                      : dataPostDetail.video_ids[selectedVideoIndex]?.local_video
                  }
                  playing={true}
                  controls={true}
                  volume={1}
                  width="100%"
                  height="415px"
                  progressInterval={1000}
                />
              </div>
            </div>
          </div>
        ) : null}
      </div>
    </div>
  );
}