// translations_en.js
const translationsEn = {
  translation: {
    welcome: "Welcome",
    research: "Research",
    royal_family: "Royal Family",
    royal_activities: "Royal Activities",
    souvenir_photos: "Souvenir Photos",
    history: "History",
    correspondence: "Correspondence",
    BMD: "BMD",
    media: "Media",
    documents: "Documents",
    messages: "Messages",
    contact_us: "Contact us",
    visitors: "Visitors",
    slogen: "Official website of HM the King-Father Norodom Sihanouk of Cambodia",
    privacy_policy: "Privacy Policy",
    tag:'Tags',
    related:'Related',
    share:"Share:",
    result_found:'Result Found',
    adresse:'Adresse',
    adress_email:"Address e-mail",
    technician_support:"Technician Support",
    phone_number:"Phone Number",
    fax:"Fax",
    royal_palce:"Royal Cabinet Royal Palace, Phnom Penh, Kingdom of Cambodia.",
    hot_news:"Latest News",
    no_data_found:"No Data Found",
    category_empty:"Categories are Empty",
    tags_empty:"Tags are Empty",
    filter:"Filter",
    label_from_search:"Search keywords",
    select_category:"Select Categroy",
    search_keyword:"Search by name, description,...",
    filmography:"Filmography",
    video:"Video",
    read_more:"Read More",
    music: 'Music',
    download_music: "Download .Mp3",

    category_media:"Madia",
    category_bmd:"BMD",
    category_messages:"Messages",
    category_souvenir_photos:"Souvenir Photos",
    category_correspondence:"Correspondence",
    category_documents:"Documents",
    category_history:"History",
    categroy_royal_activities:"Royal Activities",
    download:"Download",
    royal_family_biography:"Royal Family Biography",
    validate_date_filter: "Start date must be less then End date",
    start_date:"Start Date",
    end_date:"End Date",
    selected_year:"Select Year",
    selected_month:"Select Month",
    label_meesage_validation: "Please select a year or month value to filter",
    label_link_oldWeb:"Former website",

    copy_right :'Copyright © 2023 Royal Cabinet, All right reserved. Design by Giantfocus',

  },
};

export default translationsEn;
