import React from "react";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import "../assets/styles/skeleton.css";

const SkeletonRelated = ({ countCols, countsArray }) => {
  return (
    <SkeletonTheme baseColor="#E3E3E3" highlightColor="#BDBDBD">
      <ul className="list" style={{justifyContent: countsArray > 0 ? 'space-between' : null}}>
        {Array(countsArray)
          .fill()
          .map((item, index) => (
            <li className="d-lg-flex image-block full-width image-block" key={index} >
              <div>
                <p className="card-image">
                  <Skeleton height={55} width={55} />
                </p>
              </div>
              <div style={{ padding: 10 }}>
                <p className="card-related-title">
                  <Skeleton height={15} width={200} />
                </p>
                <p className="card-date categories">
                  <Skeleton height={15} width={200} />
                </p>
              </div>
            </li>
          ))}
      </ul>
    </SkeletonTheme>
  );
};

export default SkeletonRelated;
