import React, { useState, useEffect, useRef } from "react";
import Breadcrumbs from "../../components/breadcrumb";
import { Image } from "react-bootstrap";
import SkeletonCard from "../../components/skeletonCard";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import InfiniteScroll from "../../components/InfiniteScroll";
import {
  fetchDataRoyalFamily,
  setCurrentRoyalFamilyPage,
} from "../../redux/action";
import { useTranslation } from "react-i18next";

export default function RoyalFamily() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { data, isLoading, error, currentPage } = useSelector((state) => state.royalFamily || {});

  // const { languageCode } = useSelector((state) => state);
  const { languageCode } = useSelector((state) => state.language);
  const containerRef = useRef();
  const dataRef = useRef(data);

  useEffect(() => {
    dataRef.current = data; // Update the ref when data changes
  }, [data]);

  const renderData = () => {
    return (
      <ul className="row d-lg-flex list-unstyled image-block px-lg-0 mx-lg-0">
        {data &&
          data.map((resItem, index) => {
            return (
              <React.Fragment key={resItem.id}>
                <li className="list-royal-family col-lg-4 col-md-4 image-block full-width">
                  <div className="d-lg-flex image-block-inner">
                    <div className="post-image rounded">
                      <Link to={`/royal-family/${resItem.id}`}>
                        <Image
                          src={resItem.thumbnail}
                          alt={resItem.name}
                          className="img-responsive rounded"
                        />
                      </Link>
                    </div>

                    <div className="content-post">
                      <p className="post-title-royalFamily">{resItem.name}</p>
                      <Link
                        className="card-link"
                        to={`/royal-family/${resItem.id}`}
                      >
                       {t("read_more")}
                      </Link>
                    </div>
                  </div>
                  <div className="line"></div>
                </li>
                {index % 3 === 2 ? (
                  <div className="borderListView"></div>
                ) : null}
              </React.Fragment>
            );
          })}
      </ul>
    );
  };

  const handleScroll = () => {
    const container = containerRef.current;
    if (!container || !dataRef.current) {
      return;
    }
    const bottom =
      container.scrollTop + container.clientHeight >=
      container.scrollHeight - 1;
    if (
      bottom &&
      !isLoading &&
      dataRef.current?.length >= currentPage * 12
    ) {
      const nextPage = currentPage + 1;
      dispatch(setCurrentRoyalFamilyPage(nextPage));
    }
  };

  useEffect(() => {
    const container = containerRef.current;
    if (!container) {
      return;
    }

    container.addEventListener("scroll", handleScroll);
    return () => {
      container.removeEventListener("scroll", handleScroll);
    };
  }, [containerRef, handleScroll]);

  useEffect(() => {
    dispatch(fetchDataRoyalFamily(languageCode, currentPage))
      .then((response) => {
        if (response.data.results.length == 0) {
          const container = containerRef.current;
          container.removeEventListener("scroll", handleScroll);
        }else {
          // Scroll the container back to the top
          if(currentPage === 1 ){
            const container = containerRef.current;
            container.scrollTop = 0;
          }
        }
      })
      .catch((error) => {
        console.error("Error in fetching data:", error.message);
      });
  }, [currentPage, languageCode]);

  return (
    <div className="group-content-post royal-family">
      <Breadcrumbs breadcrumbName={t("royal_family")} />
      <div>
        <InfiniteScroll
          renderData={renderData}
          isLoading={isLoading}
          fetchMoreData={data}
          currentPage={currentPage}
          error={error}
          containerRef={containerRef}
          countCols={4}
        />
      </div>
    </div>
  );
}
