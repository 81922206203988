import React, { useState, useEffect, useRef } from "react";
import SkeletonCard from "./skeletonCard";
import { useDispatch, useSelector } from "react-redux";
import ErrorPage from "../pages/errorPage";
import Spinner from "react-bootstrap/Spinner";

const InfiniteScroll = ({
  containerRef,
  renderData,
  isLoading,
  fetchMoreData,
  currentPage,
  valueMargin,
  countCols,
  error,
}) => {

  return (
    <div ref={containerRef} className="pre-scrollables">
      <div style={{marginRight: valueMargin}} className="value-margin-mobile">
        {isLoading && currentPage === 1 ? <SkeletonCard countCols={countCols} /> : renderData()}
        {/* {currentPage >= 1 && !isLoading && renderData()} */}
      </div>
    </div>
  );
};
export default InfiniteScroll;
