import React from "react";
import Breadcrumbs from "../../components/breadcrumb";
import footerBackground from "../../assets/Images/footer_background.png";
import { Col, Image, Row } from "react-bootstrap";
import { MdLocationOn, MdEmail, MdContactPhone, MdSupport, MdOutlineArrowRight } from "react-icons/md";
import { useTranslation } from "react-i18next";

export default function Contact() {
  const {t} = useTranslation();
  const breadcrumbName = t('contact_us');
  return (
    <React.Fragment>
      <div className="default-content-page">
        <Breadcrumbs breadcrumbName={breadcrumbName} />
        <div className="non-scrollable">
          <Row>
            <Col full-width md={3}>
              <div className="content-contact">
                <h1>{t('adresse')}</h1>
                <div className="icon">
                  <MdLocationOn />
                </div>
                <p>
                {t('royal_palce')}
                </p>
              </div>
            </Col>
            <Col md={3}>
              {" "}
              <div className="content-contact">
                <h1>{t('contact_us')}</h1>
                <div className="icon">
                  <MdContactPhone />
                </div>
                <p>{t('phone_number')}: (855) 23 42 68 01</p>
                <p>{t('fax')}: (855) 23 42 68 02</p>
              </div>
            </Col>
            {/* <Col md={3}>
              <div className="content-contact">
                <h1>{t('adress_email')}</h1>
                <div className="icon">
                  <MdEmail />
                </div>
                <p>cabinet@norodomsihanouk.info</p>
              </div>
            </Col> */}
            <Col md={3}>
              <div className="content-contact">
                <h1>www.norodomsihanouk.info</h1>
                <div className="icon">
                  <MdSupport />
                </div>
                <p>{t('technician_support')}</p>
                <p><MdOutlineArrowRight/>Prince Sisowath Thomico</p>
                <p><MdOutlineArrowRight/>H.E.Lay Sok Tun</p>
                <p><MdOutlineArrowRight/>H.E.Lim Meng Kheang</p>
              </div>
            </Col>
          </Row>
        </div>
      </div>
      <div className="footer-bg-contact">
        <Image src={footerBackground} />
      </div>
    </React.Fragment>
  );
}
