import React from "react";
import { Image } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { FaCalendarAlt } from "react-icons/fa";
import { Link } from "react-router-dom";
import FormattedDate from "../components/formattedDate";

export default function SubSidebar({ relatedItems }) {
  const {t} = useTranslation();
  if (!relatedItems || !relatedItems.related_posts) {
    return null;
  }

  return (
    <div className="post-related">
      <h2 className="label-post-related">{t('related')}</h2>
      <ul style={{marginTop: 10}} className="row d-lg-flex list-unstyled image-block px-lg-0 mx-lg-0">
        {relatedItems.related_posts.length > 0 ? (
          relatedItems.related_posts.slice(0, 5).map((item, index) => (
            <li key={index} className="mainClass">
              <Link className="mh-100" to={`../articles/${item.id}`}>
                <div className="image-block-inner d-lg-flex">
                  <div className="post-image rounded">
                    <Image
                      src={item.thumbnail}
                      className="post-thum img-responsive w-100"
                      rounded
                    />
                  </div>

                  <div className="content-post">
                    <p className="post-title">{item.name}</p>
                    <FaCalendarAlt color="#032ea1" />
                    <FormattedDate date={item.date} />
                  </div>
                </div>
              </Link>
            </li>
          ))
        ) :null }
      </ul>
    </div>
  );
}
