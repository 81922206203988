import React from "react";
import { Image } from "react-bootstrap";
import { FaCalendarAlt } from "react-icons/fa";
import { Link } from "react-router-dom";
import defaultThumbnail from "../../assets/Images/default_image.png";
import FormattedDate from "../formattedDate";
import EmptyData from "./emptyData";

export default function Card({ resItem, countCols, title_type }) {
  const mainClass = `col-md-3 col-sm-6 col-lg-${countCols} image-block full-width`;
  const modifyClassPost_title =
    title_type === "title_bmd" ? `post-title ${title_type}` : "post-title";

  const getCategorySlug = (category) => {
    if (!category) return null; // If category is null, return null
    if (!category.parent) return category.slug; // If there is no parent, return the category's slug
    if (!category.parent.parent) return category.parent.slug; // If there is no grandparent, return the parent's slug
    return category.parent.parent.slug; // Otherwise, return the grandparent's slug
  };

  return (
    <ul className="row d-lg-flex list-unstyled image-block px-lg-0 mx-lg-0">
      {resItem.length > 0 ? (
        resItem.map((item, index) => {
          const slugUrl = getCategorySlug(item.category);
          return (
            <li key={item.id} className={mainClass}>
              <div className="image-block-inner">
                {item.pdf_file || item.pdf_file === null ? (
                  <a
                    href={item.pdf_file === null ? "#" : `${item.pdf_file}`}
                    target={item.pdf_file === null ? "_self" : "_blank"}
                    rel="noopener noreferrer"
                  >
                    <div className="post-image links rounded">
                      {item.thumbnail === null ? (
                        <Image
                          src={defaultThumbnail}
                          alt={item.name}
                          className="img-responsive w-100"
                        />
                      ) : (
                        // <div className="img-responsive w-100" style={{ backgroundImage: `url(${defaultThumbnail})` }}>
                        // </div>
                        // <div className="img-responsive w-100" style={{ backgroundImage: `url(${item.thumbnail})` }}>
                        // </div>
                        <Image
                          src={item.thumbnail}
                          alt={item.name}
                          className="img-responsive w-100"
                        />
                      )}
                    </div>
                  </a>
                ) : (
                  <Link className="mh-100" to={`/${slugUrl}/articles/${item.id}`}>
                    <div className="post-image rounded">
                      {item.thumbnail === null ? (
                        <Image
                          src={defaultThumbnail}
                          alt={item.name}
                          className="img-responsive w-100"
                        />
                      ) : (
                        <Image
                          src={item.thumbnail}
                          alt={item.name}
                          className="img-responsive w-100"
                        />
                      )}
                    </div>
                  </Link>
                )}
                <div className="content-post">
                  <FaCalendarAlt color="#032ea1" />
                  <FormattedDate date={item.date} />
                  <p className="post-category">
                    {item.category === null ? "uncategory" : item.category.name}
                  </p>
                  {title_type === "title_bmd" ? (
                    <a
                      href={item.pdf_file === null ? "#" : `${item.pdf_file}`}
                      target={item.pdf_file === null ? "_self" : "_blank"}
                      rel="noopener noreferrer"
                    >
                      <p className={modifyClassPost_title}>{item.name}</p>
                    </a>
                  ) : (
                    <Link className="mh-100" to={`/${slugUrl}/articles/${item.id}`}>
                      <p className={modifyClassPost_title}>{item.name}</p>
                    </Link>
                  )}

                  {title_type === "title_bmd" && (
                    <p className="post-title">{item.name}</p>
                  )}
                </div>
              </div>
            </li>
          );
        })
      ) : (
        <EmptyData />
      )}
    </ul>
  );
}
