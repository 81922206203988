import React, { useEffect, useRef, useCallback } from "react";
import Breadcrumbs from "../../components/breadcrumb";
import MainSidebar from "../../components/mainSidebar";
import ActivitiesCardList from "../../components/royalActivities/cardList";
import InfiniteScroll from "../../components/InfiniteScroll";
import { useDispatch, useSelector } from "react-redux";
import { fetchDataPostMainCategory, setCurrentPage } from "../../redux/action";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";

export default function Media({ path, categoryLabelName }) {
  const location = useLocation();
  const categorySlug = path.replace(/\s+/g, "-");
  const searchParams = new URLSearchParams(location.search);
  const searchCategory = searchParams.get("category");
  const { t } = useTranslation();
  const dispatch = useDispatch();
  
  const { data, isLoading, error, currentPage } = useSelector((state) => state.posts.media || {});
  const { filters } = useSelector((state) => state);
  const { languageCode } = useSelector((state) => state.language);
  const containerRef = useRef();
  const dataRef = useRef(data);

  useEffect(() => {
    dataRef.current = data; // Update the ref when data changes
  }, [data]);

  const renderData = useCallback(() => {
    return <ActivitiesCardList resItem={data} countCols={3} />;
  }, [data]);

  const handleScroll = () => {
    const container = containerRef.current;
    if (!container || !dataRef.current) {
      return;
    }
    const bottom =
      container.scrollTop + container.clientHeight >=
      container.scrollHeight - 1;

    if (
      bottom &&
      !isLoading &&
      dataRef.current?.length >= currentPage * 12
    ) {
      const nextPage = currentPage + 1;
      dispatch(setCurrentPage(nextPage));
    }
  };

  useEffect(() => {
    const container = containerRef.current;
    if (!container) {
      return;
    }
    container.addEventListener("scroll", handleScroll);
    return () => {
      container.removeEventListener("scroll", handleScroll);
    };
  }, [containerRef, handleScroll]);

  useEffect(() => {
    dispatch(
      fetchDataPostMainCategory(
        languageCode,
        'media',
        searchCategory === null ? categorySlug : searchCategory,
        filters.day,
        filters.month,
        filters.tag_id,
        filters.year,
        currentPage
      )
    )
      .then((response) => {
        if (response.data.results.length === 0) {
          const container = containerRef.current;
          container.removeEventListener('scroll', handleScroll);
        }else {
          // Scroll the container back to the top
          if(currentPage === 1 ){
            const container = containerRef.current;
            container.scrollTop = 0;
          }
        }
      })
      .catch((error) => {
        console.error('Error in fetching data:', error.message);
      });
  }, [
    currentPage,
    languageCode,
    categorySlug,
    filters.day,
    filters.month,
    filters.tag_id,
    filters.year,

  ]);
  

  return (
    <div className="main-wrapper d-lg-flex list-unstyled image-block px-lg-0 mx-lg-0">
      <div className="sidebar-container">
        <div className="main-sidebar">
          <MainSidebar path={path} categoryLabelName={categoryLabelName} />
        </div>
      </div>
      <div className="page-container">
        <div className="main">
          <div className="group-content-post">
            <Breadcrumbs breadcrumbName={t("media")} />
            <div>
              <InfiniteScroll
                renderData={renderData}
                isLoading={isLoading}
                fetchMoreData={data}
                currentPage={currentPage}
                error={error}
                containerRef={containerRef}
                countCols={3}
                valueMargin={10}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}





// import React, { useEffect, useRef, useCallback } from "react";
// import Breadcrumbs from "../../components/breadcrumb";
// import MainSidebar from "../../components/mainSidebar";
// import ActivitiesCardList from "../../components/royalActivities/cardList";
// import InfiniteScroll from "../../components/InfiniteScroll";
// import { useDispatch, useSelector } from "react-redux";
// import { fetchDataPostByCategory, setCurrentPage} from "../../redux/action";
// import { useTranslation } from "react-i18next";
// import { useLocation } from "react-router-dom";
// import MediaConent from "../../components/mediaConent";

// export default function Media({ path, categoryLabelName }) {
//   const location = useLocation();
//   const categorySlug = path.replace(/\s+/g, "-");
//   const searchParams = new URLSearchParams(location.search);
//   const searchCategory = searchParams.get("category");
//   const { t } = useTranslation();
//   const dispatch = useDispatch();
//   const { data, isLoading, error, currentPage } = useSelector(
//     (state) => state.posts
//   );
//   const { 
//     filters
//   } = useSelector((state) => state);

//   const { 
//     languageCode,
//   } = useSelector((state) => state.language);

//   const containerRef = useRef();
//   const dataRef = useRef(data);

//   useEffect(() => {
//     dataRef.current = data; // Update the ref when data changes
//   }, [data]);
  
//   const renderData = useCallback(() => {
//     return <ActivitiesCardList resItem={data} countCols={3} />;
//   }, [data]);

//   const handleScroll = debounce(() => {
//     console.log('dataRef.current?.length', dataRef.current?.length)
//     console.log('currentPage', currentPage)
//     const container = containerRef.current;
//     if (!container || !dataRef.current) {
//       return;
//     }
//     const bottom = container.scrollTop + container.clientHeight >= container.scrollHeight - 1;
//     if (bottom && currentPage < 2 && !isLoading && dataRef.current?.length > 11) {
//       const nextPage = currentPage + 1;
//       dispatch(setCurrentPage(nextPage));
//     }
//   }, 100);

//   useEffect(()=>{
//     const container = containerRef.current;
//     if (!container) {
//       return;
//     }

//     container.addEventListener("scroll", handleScroll);
//     return () => {
//       container.removeEventListener("scroll", handleScroll);
//     };
//   }, [containerRef, handleScroll]);

//   useEffect(() => {
//     dispatch(fetchDataPostByCategory(
//       languageCode,
//       // filters.category,
//       searchCategory === null ? categorySlug : searchCategory,
//       filters.day,
//       filters.month,
//       filters.tag_id,
//       filters.years,
//       currentPage
//     )).then((response)=>{
//       if(response.data.results.length == 0){
//         const container = containerRef.current;
//         container.removeEventListener("scroll", handleScroll);
//       }
//     }).catch((error)=>{
//       console.error("Error in fetching data:", error.message);
//     })

//   }, [
//     currentPage, 
//     languageCode,
//     // filters.category,
//     filters.day,
//     filters.month,
//     filters.tag_id,
//     filters.years,

//   ]);

//   return (
//     <div className="main-wrapper d-lg-flex list-unstyled image-block px-lg-0 mx-lg-0">
//       <div className="sidebar-container">
//         <div className="main-sidebar">
//           <MainSidebar path={path} categoryLabelName={categoryLabelName}/>
//         </div>
//       </div>
//       <div className="page-container">
//         <div className="main">
//         <div className="group-content-post">
//             <Breadcrumbs breadcrumbName={t("media")} />
//             {/* {searchCategory === null ? (
//               <div className="pre-scrollables">
//                 <MediaConent />
//               </div>
//              ) : ( */}
//               <div>
//                 <InfiniteScroll
//                   renderData={renderData}
//                   isLoading={isLoading}
//                   fetchMoreData={data}
//                   currentPage={currentPage}
//                   error={error}
//                   containerRef={containerRef}
//                   countCols={3}
//                   valueMargin={10}
//                 />
//               </div>
//             {/* )}  */}
//           </div>
//         </div>
//       </div>
//     </div>
//   );
// }
