import React, { useState, useEffect } from "react";
import { Dropdown, Button, Form } from "react-bootstrap";
import { AiFillCaretRight, AiFillFilter } from "react-icons/ai";
import Navbar from "react-bootstrap/Navbar";
import Offcanvas from "react-bootstrap/Offcanvas";
import axiosInstance from "../context/axios";
import { Link, useNavigate } from "react-router-dom";
import {
  fetchDataPostByCategory,
  fetchDataPostMainCategory,
  fetchDataBMDFilter,
  setCurrentPage,
} from "../redux/action";
import { useDispatch, useSelector } from "react-redux";
import DatePicker from "react-datepicker";
import { parseISO, format } from "date-fns";
import "react-datepicker/dist/react-datepicker.css";
import { useTranslation } from "react-i18next";

export default function MainSidebar(props) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const page = 1;
  const { filters } = useSelector((state) => state);
  const { languageCode } = useSelector((state) => state.language);
  const [year, setYear] = useState(null);
  const [date, setDate] = useState("");
  const [month, setMonth] = useState(null);
  const [tagIds, setTagIds] = useState("");
  const [show, setShow] = useState(false);
  const [listCategory, setListCategory] = useState([]);
  const [listTags, setListTags] = useState([]);
  const [categoryName, setCategoryName] = useState("");
  const [messageValidate, setMessageValidate] = useState(false)
  const slugMainCategory = props.path.replace(/\s+/g, "-");
  const [formattedDate, setFormattedDate] = useState("");
  const navigate = useNavigate();
  // covert slug to post tpye of each state
  function formatSlugToPostTypeState(str) {
    return str.replace(/-([a-z])/g, function (match, letter) {
      return letter.toUpperCase();
    });
  }

  const formatDateString = (dateString) => {
    if (dateString) {
      const [year, month, day] = dateString.split("-");
      return `${day}/${month}/${year}`;
    }
    return "";
  };

  const handleChangeDate = (e) => {
    setDate(e.target.value);
    setFormattedDate(formatDateString(e.target.value));
  };

  const handleMonthChange = (date) => {
    try {
      // Check if date is a valid Date object
      if (date instanceof Date && !isNaN(date.getTime())) {
        setMonth(date);
        if (date) {
          setMessageValidate(false)
        }
      } else if (typeof date === "string") {
        const parsedDate = parseISO(date);
        setMonth(parsedDate);
      }
    } catch (error) {
      console.error("Error handling month change:", error.message);
    }
  };

  const handleYearChange = (date) => {
    try {
      // Check if date is a valid Date object
      if (date instanceof Date && !isNaN(date.getTime())) {
        setYear(date);
        if (date) {
          setMessageValidate(false)
        }
        setMessageValidate(false)
      } else if (typeof date === "string") {
        const parsedDate = parseISO(date);
        setYear(parsedDate);
      }
    } catch (error) {
      console.error("Error handling Year change:", error.message);
    }
  };

  const handleItemClick = async (categorySlug) => {
    const resFormatPostTypeState = formatSlugToPostTypeState(slugMainCategory);
    if (slugMainCategory !== "bmd") {
      await dispatch(
        fetchDataPostByCategory(
          languageCode,
          resFormatPostTypeState,
          categorySlug === undefined ? slugMainCategory : categorySlug,
          filters.day,
          filters.month,
          filters.tag_id,
          filters.years,
          page
        )
      );
    } else {
      await dispatch(
        fetchDataBMDFilter(
          languageCode,
          categorySlug === undefined ? slugMainCategory : categorySlug,
          null,
          null,
          null,
          null,
          page
        )
      );
    }
  };

  const handleItemSelected = (categoryName) => {
    setCategoryName(categoryName);
  };

  const handleTagsChange = (ids) => {
    if (tagIds.includes(ids)) {
      setTagIds(tagIds.filter((id) => id !== ids));
    } else {
      setTagIds([...tagIds, ids]);
    }
  };

  const handleItemClickFilter = async () => {
    const resFormatPostTypeState = formatSlugToPostTypeState(slugMainCategory);
    setShow(false);
    const formatDate = date !== "null" ? new Date(date) : "";
    const formatMonth = month ? format(month, "M") : null;
    const formatYear = year ? format(year, "yyyy") : null;

    if (formatYear === null && formatMonth === null) {
      setTimeout(() => {
        setMessageValidate(true)
      }, 200);
      return
    }

    const formatTagIds = tagIds ? tagIds.join(",") : "";
    let dayOfMonth;
    if (!isNaN(formatDate.getTime())) {
      dayOfMonth = formatDate.getDate();
    }

    try {
      if (slugMainCategory === "bmd") {
        await dispatch(
          fetchDataBMDFilter(
            languageCode,
            slugMainCategory,
            dayOfMonth,
            formatMonth,
            formatTagIds,
            formatYear,
            page,
            navigate
          )
        );
      } else {
        await dispatch(
          fetchDataPostMainCategory(
            languageCode,
            resFormatPostTypeState,
            slugMainCategory,
            dayOfMonth,
            formatMonth,
            formatTagIds,
            formatYear,
            page,
            navigate
          )
        );
      }
      dispatch(setCurrentPage(page));
    } catch (error) {
      console.error("Error in fetching data:", error.message);
    }
  };


  const fetchDataCategories = async () => {
    try {
      await axiosInstance
        .get(
          `${languageCode}/api/post/category/?parent_slug=${slugMainCategory}`
        )
        .then((response) => {
          setListCategory(response.data);
        });
    } catch (error) {
      console.log(error);
    }
  };

  const fetchDataTags = async () => {
    try {
      await axiosInstance.get(`/api/post/tag/`).then((response) => {
        setListTags(response.data);
      });
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchDataCategories();
    fetchDataTags();
  }, [languageCode]);

  return (
    <React.Fragment>
      {props.path != "none" ? (
        <React.Fragment>
          <div className="content-main-sidebar">
            <Dropdown>
              <Dropdown.Toggle variant="none" id="dropdown-category">
                {!categoryName ? props.categoryLabelName : categoryName.name}
              </Dropdown.Toggle>

              <Dropdown.Menu>
                {listCategory && listCategory.count > 0 ? (
                  listCategory.results.map((resCategories) => {
                    return (
                      <React.Fragment key={resCategories.id}>
                        <Dropdown.Item
                          eventKey={resCategories.name}
                          onClick={() => {
                            handleItemClick(resCategories.slug);
                            handleItemSelected(resCategories);
                          }}
                          as={Link}
                          to={`./?category=${resCategories.slug}`}
                        >
                          <AiFillCaretRight size={11} color="#767676" />
                          {resCategories.name}
                        </Dropdown.Item>

                        {resCategories &&
                          resCategories.children.map((resCateChild) => {
                            return (
                              <React.Fragment key={resCateChild.id}>
                                <Dropdown.Item
                                  eventKey={resCateChild.name}
                                  onClick={() => {
                                    handleItemClick(resCateChild.slug);
                                    handleItemSelected(resCateChild);
                                  }}
                                  as={Link}
                                  to={`./?category=${resCateChild.slug}`}
                                >
                                  <div className="category-child">
                                    <AiFillCaretRight
                                      size={11}
                                      color="#767676"
                                    />
                                    {resCateChild.name}
                                  </div>
                                </Dropdown.Item>

                                {/* {resCateChild &&
                                  resCateChild.children.map((resCateChilds) => {
                                    return (
                                      <Dropdown.Item
                                        key={resCateChilds.id}
                                        eventKey={resCateChilds.name}
                                        onClick={() => {
                                          handleItemClick(resCateChilds.slug);
                                          handleItemSelected(resCateChilds);
                                        }}
                                        as={Link}
                                        to={`./?category=${resCateChilds.slug}`}
                                      >
                                        <div className="category-child">
                                          <AiFillCaretRight
                                            size={11}
                                            color="#767676"
                                          />
                                          {resCateChilds.name}
                                        </div>
                                      </Dropdown.Item>
                                    );
                                  })} */}
                              </React.Fragment>
                            );
                          })}
                      </React.Fragment>
                    );
                  })
                ) : (
                  <Dropdown.Item disabled>{t("category_empty")}</Dropdown.Item>
                )}
              </Dropdown.Menu>
            </Dropdown>
          </div>
          <div className="height-between" style={{ height: 30 }}></div>
        </React.Fragment>
      ) : null}

      {[false, "lg"].map((expand) => (
        <Navbar
          key={expand}
          expand={expand}
          className={`bg-body-tertiary ${expand}`}
        >
          <div className="view-only-mobile">
            <h2 onClick={() => setShow(true)}>
              {t("filter")}
              <AiFillFilter size={15} style={{ marginLeft: 5 }}></AiFillFilter>
            </h2>
          </div>
          {/* <Navbar.Toggle
            aria-controls={`offcanvasNavbar-expand-${expand}`}
            onClick={() => setShow(true)}
          /> */}
          <Navbar.Offcanvas
            show={show}
            onHide={() => setShow(false)}
            id={`offcanvasNavbar-expand-${expand}`}
            aria-labelledby={`offcanvasNavbarLabel-expand-${expand}`}
            placement="start"
          >
            <Offcanvas.Header closeButton></Offcanvas.Header>
            <Offcanvas.Body>
              <div className="content-filter">
                <h2>{t("filter")}</h2>
                <div className="content-main-sidebar">
                  <div className="d-lg-flex">
                    <div className="years">
                      <DatePicker
                        className={messageValidate ? 'form-control message-validation' : 'form-control'}
                        selected={year}
                        onChange={(date) => handleYearChange(date)}
                        placeholderText={t('selected_year')}
                        showYearPicker
                        dateFormat="yyyy"
                        yearItemNumber={10}
                      />
                    </div>

                    <div className="months">
                      <DatePicker
                        className={messageValidate ? 'form-control message-validation' : 'form-control'}
                        selected={month}
                        placeholderText={t('selected_month')}
                        onChange={(date) => handleMonthChange(date)}
                        dateFormat="MMMM"
                        excludeDates={[
                          1661990400000, 1664582400000, 1667260800000,
                          1672531200000,
                        ]}
                        showMonthYearPicker
                        showFullMonthYearPicker
                        showTwoColumnMonthYearPicker
                      />
                    </div>
                  </div>

                  {messageValidate ?<p className="lable-message-validate">{t('label_meesage_validation')}</p>:null}

                  <div className="date-picker">
                    <Form.Group controlId="date">
                      <Form.Control
                        type="date"
                        name="datepic"
                        placeholder="dd/mm/yyyy"
                        value={date}
                        onChange={handleChangeDate}
                        style={{ margin: 0 }}
                      />
                    </Form.Group>

                    <Form.Group>
                      <Form.Control
                        type="text"
                        name="datevalue"
                        placeholder="dd/mm/yyyy"
                        className="form-controls display-date-selected"
                        value={formattedDate} // Use formattedDate for display
                        readOnly // Ensure the field is read-only
                      />
                    </Form.Group>
                  </div>
                  <div className="tages">
                    <Dropdown>
                      <Dropdown.Toggle variant="none" id="dropdown-basic">
                        {t("tag")}
                      </Dropdown.Toggle>

                      <Dropdown.Menu>
                        {listTags && listTags.count > 0 ? (
                          listTags.results.map((resTags) => {
                            return (
                              <React.Fragment key={resTags.id}>
                                <Form.Check
                                  type="checkbox"
                                  label={resTags.name}
                                  checked={tagIds.includes(resTags.id)}
                                  onChange={() => handleTagsChange(resTags.id)}
                                />
                              </React.Fragment>
                            );
                          })
                        ) : (
                          <Dropdown.Item disabled>
                            {t("category_empty")}
                          </Dropdown.Item>
                        )}
                      </Dropdown.Menu>
                    </Dropdown>
                  </div>
                  <div className="but-search">
                    <Button
                      onClick={handleItemClickFilter}
                      variant="none"
                    >
                      {t("research")}
                    </Button>
                  </div>
                </div>
              </div>
            </Offcanvas.Body>
          </Navbar.Offcanvas>
        </Navbar>
      ))}
    </React.Fragment>
  );
}
