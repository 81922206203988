
// Action type get data royal family
export const FETCH_DATA_ROYAL_FAMILY_REQUEST = 'FETCH_DATA_ROYAL_FAMILY_REQUEST';
export const FETCH_DATA_ROYAL_FAMILY_SUCCESS = 'FETCH_DATA_ROYAL_FAMILY_SUCCESS';
export const FETCH_DATA_ROYAL_FAMILY_FAILURE = 'FETCH_DATA_ROYAL_FAMILY_FAILURE';

// Action type get date post
export const FETCH_DATA_POST_REQUEST = 'FETCH_DATA_POST_REQUEST';
export const FETCH_DATA_POST_SUCCESS = 'FETCH_DATA_POST_SUCCESS';
export const FETCH_DATA_POST_FAILURE = 'FETCH_DATA_POST_FAILURE';


// Action type get date post Search
export const FETCH_DATA_POST_SEARCH_REQUEST = 'FETCH_DATA_POST_SEARCH_REQUEST';
export const FETCH_DATA_POST_SEARCH_SUCCESS = 'FETCH_DATA_POST_SEARCH_SUCCESS';
export const FETCH_DATA_POST_SEARCH_FAILURE = 'FETCH_DATA_POST_SEARCH_FAILURE';


// Action type get date BMD
export const FETCH_DATA_BMD_REQUEST = 'FETCH_DATA_BMD_REQUEST';
export const FETCH_DATA_BMD_SUCCESS = 'FETCH_DATA_BMD_SUCCESS';
export const FETCH_DATA_BMD_FAILURE = 'FETCH_DATA_BMD_FAILURE';
