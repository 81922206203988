
import React, { useEffect } from "react";
export default function ErrorPage() {
  return (
    <div className="message-error group-content-post royal-family">
      <b style={{ fontSize: 25, color: "#022ea1" }}>404 Not Found</b>
      <p style={{ color: "#022ea1" }}>
        Sorry, the page you are looking for does not exist.
      </p>
    </div>
  );
}
