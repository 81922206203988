import React, { useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { fetchDataRoyalFamily } from "../redux/action";
import { Navbar, Nav } from "react-bootstrap";
import { AiFillCaretRight } from "react-icons/ai";

export default function RoyalFamilySidebar() {
  const { data, isLoading, error, currentPage } = useSelector(
    (state) => state.royalFamily || {}
  );
  const location = useLocation();
  const splitArray = location.pathname.split("/");
  const lastsegmentSlug = JSON.parse(splitArray[splitArray.length - 1]);

  const { t } = useTranslation();
  const dataRef = useRef(data);
  const dispatch = useDispatch();
  // const { languageCode } = useSelector((state) => state);
  const { languageCode } = useSelector((state) => state.language);
  useEffect(() => {
    dataRef.current = data; // Update the ref when data changes
  }, [data]);

  useEffect(() => {
    dispatch(fetchDataRoyalFamily(languageCode, currentPage))
      .then((response) => {
        console.log();
      })
      .catch((error) => {
        console.error("Error in fetching data:", error.message);
      });
  }, [currentPage, languageCode]);

  return (
    <div>
      <h1>{t("royal_family_biography")}</h1>
      <Navbar className="bg-body-tertiary nav-member-family">
        <Navbar.Toggle className="mobile-toogle" />
        <Navbar.Collapse>
          <Navbar.Text>
            {dataRef &&
              dataRef.current.length > 0 &&
              dataRef.current.map((resItemMember) => {
                return (
                  <>
                    <Nav.Item
                      className={
                        lastsegmentSlug === resItemMember.id
                          ? "current-item"
                          : ""
                      }
                    >
                      <AiFillCaretRight size={12} color="#032ea1" />{" "}
                      <Link
                        className={`hover-animation`}
                        to={`/royal-family/${resItemMember.id}`}
                      >
                        {resItemMember.name}
                      </Link>
                    </Nav.Item>
                  </>
                );
              })}
          </Navbar.Text>
        </Navbar.Collapse>
      </Navbar>
    </div>
  );
}
