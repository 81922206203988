import React, { useState, useEffect } from "react";
import Button from "react-bootstrap/Button";
import Container from "react-bootstrap/Container";
import Form from "react-bootstrap/Form";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import NavDropdown from "react-bootstrap/NavDropdown";
import Offcanvas from "react-bootstrap/Offcanvas";
import { Link, redirect, useNavigate, useLocation } from "react-router-dom";
import { RiSearch2Line } from "react-icons/ri";
import { IoClose } from "react-icons/io5";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchDataSearchPost,
  setCurrentPage,
  fetchDataPost,
  fetchDataPostMainCategory,
  fetchDataBMD,
  setCurrentBMDPage,
  resetFilters
} from "../redux/action";
import LanguageSelector from "./languages";
import { useTranslation } from "react-i18next";

export default function Navbars() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { languageCode } = useSelector((state) => state.language);
  const [show, setShow] = useState(false);
  const [sticky, setSticky] = useState("");
  const [formSearch, setFormSearch] = useState("");
  const [showForm, setShowForm] = useState(false);
  const location = useLocation();
  const splitPathname = location.pathname;
  const currentPartUrl = splitPathname.split("/")[1];

  function formatSlugToPostTypeState(str) {
    return str.replace(/-([a-z])/g, function (match, letter) {
      return letter.toUpperCase();
    });
  }
  const resFormatPostTypeState = formatSlugToPostTypeState(currentPartUrl);
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    let page = 1;
    if (e.key === "Enter") {
      e.preventDefault();
      await dispatch(
        fetchDataSearchPost(
          languageCode,
          page,
          formSearch,
          null,
          null,
          null,
          navigate,
          null,
        )
      );
      setFormSearch("");
      setShowForm(false);
    }
  };

  const handleNavigation = (path, category) => {
    setShow(false);
    let fetchDataAction;
    let page = 1

    switch (category) {
      case "bmd":
        dispatch(setCurrentBMDPage(page));
        fetchDataAction = fetchDataBMD(
          languageCode,
          category,
          null,
          null,
          null,
          null,
          page,
        );
        break;
      case "welcome":
        dispatch(setCurrentPage(page));
        fetchDataAction = fetchDataPost('recentNews', languageCode, page, {});

        break;
      default:
        dispatch(setCurrentPage(page));
        fetchDataAction = fetchDataPostMainCategory(
          languageCode,
          resFormatPostTypeState,
          category,
          null,
          null,
          null,
          null,
          page,
        );
    }

    // dispatch(fetchDataAction)

    dispatch(resetFilters());
    // Navigate to the specified path
    if (path != null) {
      navigate(path);
    } else {
      navigate("/");
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", isSticky);
    return () => {
      window.removeEventListener("scroll", isSticky);
    };
  }, [dispatch]);

  const isSticky = () => {
    /* Method that will fix header after a specific scrollable */
    const scrollTop = window.scrollY;
    const stickyClass = scrollTop >= 100 ? "is-sticky" : "";
    setSticky(stickyClass);
  };
  const classes = `header-section ${sticky}`;

  return (
    <>
      {[false, "lg"].map((expand) => (
        <Navbar
          key={expand}
          expand={expand}
          className={`bg-body-tertiary ${expand} ${classes}`}
        >
          <Container>
            {!showForm && (
              <Navbar.Toggle
                aria-controls={`offcanvasNavbar-expand-${expand}`}
                onClick={() => setShow(true)}
              />
            )}
            {/* <Navbar.Collapse id="navbarScroll"> */}
            {showForm ? (
              <Form className="d-flex">
                <Form.Group controlId="formSearch" style={{ width: "100%" }}>
                  <Form.Control
                    autoFocus
                    size="md"
                    className="me-2"
                    type="text"
                    aria-label="Search"
                    name="title"
                    placeholder={t("search_keyword")}
                    value={formSearch}
                    onKeyDown={handleSubmit}
                    onChange={(e) => setFormSearch(e.target.value)}
                  />
                </Form.Group>
              </Form>
            ) : (
              <Navbar.Offcanvas
                show={show}
                onHide={() => setShow(false)}
                id={`offcanvasNavbar-expand-${expand}`}
                aria-labelledby={`offcanvasNavbarLabel-expand-${expand}`}
                placement="start"
              >
                <Offcanvas.Header closeButton></Offcanvas.Header>
                <Offcanvas.Body>
                  <Nav
                    defaultActiveKey="/home"
                    as="ul"
                    className={`me-auto my-2 my-lg-0 ${!expand ? "align-items-center" : null
                      }`}
                  >
                    <Nav.Item as="li">
                      <Nav.Link
                        onClick={() => handleNavigation(null, "welcome")}
                        className={
                          currentPartUrl === ""
                            ? "hover-underline-animation current-item"
                            : "hover-underline-animation"
                        }
                      >
                        {t("welcome")}
                      </Nav.Link>
                    </Nav.Item>

                    <Nav.Item as="li">
                      <Nav.Link
                        onClick={() => handleNavigation("/royal-family", "")}
                        className={
                          currentPartUrl === "royal-family"
                            ? "hover-underline-animation current-item"
                            : "hover-underline-animation"
                        }
                      >
                        {t("royal_family")}
                      </Nav.Link>
                    </Nav.Item>

                    <Nav.Item as="li">
                      <Nav.Link
                        onClick={() =>
                          handleNavigation(
                            "/royal-activities",
                            "royal-activities"
                          )
                        }
                        className={
                          currentPartUrl === "royal-activities"
                            ? "hover-underline-animation current-item"
                            : "hover-underline-animation"
                        }
                      >
                        {t("royal_activities")}
                      </Nav.Link>
                    </Nav.Item>

                    <Nav.Item as="li">
                      <Nav.Link
                        onClick={() =>
                          handleNavigation(
                            "/souvenir-photos",
                            "souvenir-photos",
                            1
                          )
                        }
                        className={
                          currentPartUrl === "souvenir-photos"
                            ? "hover-underline-animation current-item"
                            : "hover-underline-animation"
                        }
                      >
                        {t("souvenir_photos")}
                      </Nav.Link>
                    </Nav.Item>

                    <Nav.Item as="li">
                      <Nav.Link
                        onClick={() => handleNavigation("/history", "history")}
                        className={
                          currentPartUrl === "history"
                            ? "hover-underline-animation current-item"
                            : "hover-underline-animation"
                        }
                      >
                        {t("history")}
                      </Nav.Link>
                    </Nav.Item>

                    <Nav.Item as="li">
                      <Nav.Link
                        onClick={() =>
                          handleNavigation("/correspondence", "correspondence")
                        }
                        className={
                          currentPartUrl === "correspondence"
                            ? "hover-underline-animation current-item"
                            : "hover-underline-animation"
                        }
                      >
                        {t("correspondence")}
                      </Nav.Link>
                    </Nav.Item>
                    <Nav.Item as="li">
                      <Nav.Link
                        onClick={() => handleNavigation("/BMD", "bmd")}
                        className={
                          currentPartUrl === "BMD"
                            ? "hover-underline-animation current-item"
                            : "hover-underline-animation"
                        }
                      >
                        {t("BMD")}
                      </Nav.Link>
                    </Nav.Item>
                    <Nav.Item as="li">
                      <Nav.Link
                        onClick={() => handleNavigation("/media", "media")}
                        className={
                          currentPartUrl === "media"
                            ? "hover-underline-animation current-item"
                            : "hover-underline-animation"
                        }
                      >
                        {t("media")}
                      </Nav.Link>
                    </Nav.Item>
                    <Nav.Item as="li">
                      <Nav.Link
                        onClick={() =>
                          handleNavigation("/documents", "documents")
                        }
                        className={
                          currentPartUrl === "documents"
                            ? "hover-underline-animation current-item"
                            : "hover-underline-animation"
                        }
                      >
                        {t("documents")}
                      </Nav.Link>
                    </Nav.Item>
                    <Nav.Item as="li">
                      <Nav.Link
                        onClick={() =>
                          handleNavigation("/messages", "messages")
                        }
                        className={
                          currentPartUrl === "messages"
                            ? "hover-underline-animation current-item"
                            : "hover-underline-animation"
                        }
                      >
                        {t("messages")}
                      </Nav.Link>
                    </Nav.Item>
                  </Nav>
                </Offcanvas.Body>
              </Navbar.Offcanvas>
            )}
            <div className="navbar-collapse content-right" id="navbarNav">
              <div className="wrappers">
                {showForm ? (
                  <Button
                    className="but-close-search"
                    variant="none"
                    onClick={() => setShowForm(false)}
                  >
                    <IoClose size={22} />
                  </Button>
                ) : (
                  <div className="post-search">
                    <Button
                      className="but-show-search"
                      variant="none"
                      onClick={() => setShowForm(true)}
                    >
                      <RiSearch2Line size={22} />
                    </Button>
                  </div>
                )}
              </div>

             
              <LanguageSelector />
            </div>
          </Container>
        </Navbar>
      ))}
    </>
  );
}
