import React from "react";
import { Container, Nav, Navbar } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

export default function Footer() {
  const {t} = useTranslation();
  return (
    <footer className="footer footer-bg-color">
      <div className="container d-flex flex-row content-footer justify-content-between align-items-center">
        <span className="footer-copyright">
          {t('copy_right')}
        </span>
        <Navbar data-bs-theme="dark" className="listView">
          <Container>
            <Nav as="ul" className="me-auto">
              <Nav.Item as="li">
                <Link className="nav-link" to="/contact">
                {t('contact_us')}
                </Link>
              </Nav.Item>
              <Nav.Item as="li">
                <Link className="nav-link" to="#">
                {t('privacy_policy')}
                </Link>
              </Nav.Item>
            </Nav>
          </Container>
        </Navbar>
      </div>
    </footer>
  );
}
