import React, { useEffect, useState } from "react";
import Breadcrumbs from "../../components/breadcrumb";
import { Image } from "react-bootstrap";
import { Link, useParams } from "react-router-dom";
import axiosInstance from "../../context/axios";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import RoyalFamilySidebar from "../../components/royalFamilySidebar";

export default function RoyalFamilyDetail() {
  const { id: postId } = useParams();
  const { t } = useTranslation();
  const [postFamilyDetail, setPostFamilyDetail] = useState({});
  const dataParagraph = postFamilyDetail.bio_summary;
  // const { languageCode } = useSelector((state) => state);
  const { languageCode } = useSelector((state) => state.language);
  const breadcrumbName = t("royal_family");

  useEffect(() => {
    const postDetail = async () => {
      try {
        await axiosInstance
          .get(`${languageCode}/api/royal-family/${postId}/`)
          .then((result) => {
            setPostFamilyDetail(result.data);
          });
      } catch (error) {
        console.log({ error });
      }
    };
    if (postId) {
      postDetail();
    }
  }, [postId]);
  return (
    <div className="d-lg-flex px-lg-0 mx-lg-0 detail-royal-family">
      <div className="sidebar-royal-member">
        <RoyalFamilySidebar />
      </div>
      <div className="content-post-detail">
        <div className="postTitle">
          <h1>{postFamilyDetail.full_name}</h1>
        </div>
        <Breadcrumbs
          breadcrumbName={breadcrumbName}
          breadcrumbRoyalFamily={postFamilyDetail && postFamilyDetail.name}
          slug={"royal-family"}
        />
        <div className="pre-scrollables">
          <div className="info-content-post">
            <div className="d-flex flex-row">
              <p>
                <Image
                  className="post-image rounded"
                  src={postFamilyDetail.thumbnail}
                />
                {postFamilyDetail.description}
              </p>
            </div>
            <br />
            <div
              style={{ paddingRight: 15 }}
              dangerouslySetInnerHTML={{ __html: dataParagraph }}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
