import React, { useState, useEffect, useRef } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { fetchDataTagPost, setCurrentPage } from "../../redux/action";
import ActivitiesCardList from "../../components/royalActivities/cardList";
import Breadcrumbs from "../../components/breadcrumb";
import InfiniteScroll from "../../components/InfiniteScroll";
import MainSidebar from "../../components/mainSidebar";
import { useTranslation } from "react-i18next";

export default function Tags(props) {
  const { t } = useTranslation();
  const location = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { data, isLoading, error, currentPage } = useSelector((state) => state.posts.tags || {});

  // const { languageCode } = useSelector((state) => state);
  const { languageCode } = useSelector((state) => state.language);
  const containerRef = useRef();
  const dataRef = useRef(data);

  useEffect(() => {
    dataRef.current = data; // Update the ref when data changes
  }, [data]);

  // const test = useParams();
  const renderData = () => {
    return <ActivitiesCardList resItem={data} countCols={3} />;
  };

  const searchParams = new URLSearchParams(location.search);
  const tagId = searchParams.get("tag_ids");
  const [loading, setLoading] = useState(isLoading);

  const handleScroll = () => {
    const container = containerRef.current;
    if (!container || !dataRef.current) {
      return;
    }
    const bottom =
      container.scrollTop + container.clientHeight >=
      container.scrollHeight - 1;

    if (
      bottom &&
      !isLoading &&
      dataRef.current?.length >= currentPage * 12
    ) {
      const nextPage = currentPage + 1;
      dispatch(setCurrentPage(nextPage));
    }
  };

  useEffect(() => {
    const container = containerRef.current;
    if (!container) {
      return;
    }
    container.addEventListener("scroll", handleScroll);
    return () => {
      container.removeEventListener("scroll", handleScroll);
    };
  }, [containerRef, handleScroll]);


  useEffect(() => {
    try {
      dispatch(fetchDataTagPost(languageCode, 'tags', tagId, currentPage, navigate, {}))
        .then((response) => {
          if (response.data.results.length === 0) {
            const container = containerRef.current;
            container.removeEventListener('scroll', handleScroll);
          } else {
            // Scroll the container back to the top
            if (currentPage === 1) {
              const container = containerRef.current;
              container.scrollTop = 0;
            }
          }
        })
    } catch (error) {
      console.error("Error in fetching data:", error.message);
    }
  }, [
    currentPage,
    languageCode,
    tagId
  ]);

  return (
    <div className="main-wrapper d-lg-flex list-unstyled image-block px-lg-0 mx-lg-0">
      {/* <div className="sidebar-container">
        <div className="main-sidebar">
        <MainSidebar path={"none"} />
        </div>
      </div> */}
      <div className="page-container" style={{ width: "100%" }}>
        <div className="main">
          <div className="group-content-post tags-page">
            <Breadcrumbs breadcrumbName={t("tag")} />
            <div>
              <InfiniteScroll
                renderData={renderData}
                isLoading={isLoading}
                fetchMoreData={data}
                currentPage={currentPage}
                error={error}
                containerRef={containerRef}
                countCols={3}
                valueMargin={10}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
