
import React, { useState, useEffect } from "react";
import defaultThumbnail from "../assets/Images/default_image.png";
import { Image } from "react-bootstrap";

function WatermarkImage({ src, alt, onClick }) {
  const apiUrl = process.env.REACT_APP_API_BASE_URL;
    return (
      <div className="App">
        
         <Image
            // src={`${apiUrl}image/?image_path=${src}`}
            src={src}
            alt={alt}
            onClick={onClick}
            className="img-responsive w-100"
          />
      </div>
    );
  }
  
export default WatermarkImage;