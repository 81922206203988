// translations_fr.js
const translationsFr = {
  translation: {
    welcome: "Accueil",
    research: "Recherche",
    royal_family: "Famille Royale",
    royal_activities: "Activités Royales",
    souvenir_photos: "Photos Souvenirs",
    history: "Histoire",
    correspondence: "Correspondance",
    BMD: "BMD",
    media: "Média",
    documents: "Documents",
    messages: "Messages",
    contact_us: "Contactez-nous",
    visitors: "Visiteurs",
    slogen: "Site officiel de SM le Roi-Père Norodom Sihanouk du Cambodge",
		privacy_policy: "politique de confidentialité",
		tag:'Étiqueter',
		related:'En rapport',
		share:"Partager:",
		result_found:'Résultat trouvé',
		adresse:'Adresse',
		adress_email:"Adresse e-mail",
		technician_support:"Assistance technique",
		phone_number:"numéro de téléphone",
		fax:"Fax",
		royal_palce:"Cabinet Royal Palais Royal, Phnom Penh, Royaume du Cambodge.",
		hot_news:"Nouvelles",
    no_data_found:"Aucune donnée disponible",
    category_empty:"Les catégories sont vides",
    tags_empty:"Les balises sont vides",
    filter:"Filtre",
    label_from_search:"Rechercher des mots-clés:",
    select_category:"Choisir une catégorie",
    search_keyword:"Recherche par mots-clés...",
    filmography:"Filmographie",
    video:"Vidéo",
    read_more:"En savoir plus",
    music: 'Musique',
    download_music:"télécharger",


    category_media:"Médias",
    category_bmd:"BMD",
    category_messages:"Messages",
    category_souvenir_photos:"Photos souvenirs",
    category_correspondence:"Correspondance",
    category_documents:"Documents",
    category_history:"Histoire",
    categroy_royal_activities:"Activités royales",
    download:"Télécharger",
    royal_family_biography:'Biographie de la famille royale',
    validate_date_filter: "La date de début doit être inférieure à la date de fin",
    start_date:"Date de début",
    end_date:"Date de fin",
    selected_year:"Sélectionnez l'année",
    selected_month:"Sélectionnez un mois",
    label_meesage_validation:"Veuillez sélectionner une valeur d'année ou de mois à filtrer",
    label_link_oldWeb:"Ancien website",
		
		copy_right:"Copyright © 2023 Cabinet Royal, Tous droits réservés. Conception par Giantfocus",

  },
};

export default translationsFr;
