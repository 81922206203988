// FormComponent.js
import React, { useState, useEffect } from "react";
import {
  FormGroup,
  Form,
  Button,
  NavDropdown,
  Dropdown,
} from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { fetchDataSearchPost } from "../redux/action";
import { AiFillCaretRight } from "react-icons/ai";
import axiosInstance from "../context/axios";
import { useTranslation } from "react-i18next";
import DatePicker from "react-datepicker";

export default function SearchForm({ searchData, searchCategory }) {
  const { t } = useTranslation();
  const [formSearch, setFormSearch] = useState(searchData);
  const [seletedCategory, setSelectedCategory] = useState(searchCategory);
  const [listCategory, setListCategory] = useState([]);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [isOpen, setIsOpen] = useState(false);
  const { languageCode } = useSelector((state) => state.language);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [tagIds, setTagIds] = useState("");
  const [listTags, setListTags] = useState([]);
  const [validated, setValidated] = useState(false);
  const [validatedStartDate, setValidatedStartDate] = useState(false);
  const [validatedEndDate, setValidatedEndDate] = useState(false);
  const [validate_Start_End_Date, setValidate_Start_End_Date] = useState(false);
  const [formattedStartDate, setFormattedStartDate] = useState("");
  const [formattedEndDate, setFormattedEndDate] = useState("");

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const handleSelectedCategory = (eventKey) => {
    setSelectedCategory(eventKey);
    toggleDropdown();
  };

  const getParentCategory = async () => {
    try {
      await axiosInstance.get(`/api/post/maincategory/`).then((response) => {
        setListCategory(response.data);
      });
    } catch (error) {
      console.log(error);
    }
  };

  const fetchDataTags = async () => {
    try {
      await axiosInstance.get(`/api/post/tag/`).then((response) => {
        setListTags(response.data);
      });
    } catch (error) {
      console.log(error);
    }
  };

  const handleTagsChange = (ids) => {
    if (tagIds.includes(ids)) {
      setTagIds(tagIds.filter((id) => id !== ids));
    } else {
      setTagIds([...tagIds, ids]);
    }
  };

  const formatDateString = (dateString) => {
    if (dateString) {
      const [year, month, day] = dateString.split("-");
      return `${day}/${month}/${year}`;
    }
    return "";
  };

  const handleChangeStartDate = (e) => {
    setStartDate(e.target.value);
    setValidatedStartDate(true);
    setFormattedStartDate(formatDateString(e.target.value));
  };

  const handleChangeEndDate = (e) => {
    setEndDate(e.target.value);
    setValidatedEndDate(true);
    setFormattedEndDate(formatDateString(e.target.value));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const page = 1;

    if (startDate > endDate) {
      setValidate_Start_End_Date(true);
      return;
    } else {
      setValidate_Start_End_Date(false);
    }

    if (!startDate && !endDate) {
      setValidated(true); // Set form as validated
      // return; // Exit function as no action is needed
    } else if (!startDate && endDate) {
      // Only end date is selected
      handleChangeStartDate(e); // Trigger change event for start date
      return; // Exit function as the action is handled by handleChangeStartDate
    } else if (startDate && !endDate) {
      // Only start date is selected
      handleChangeEndDate(e); // Trigger change event for end date
      return; // Exit function as the action is handled by handleChangeEndDate
    }

    dispatch(
      fetchDataSearchPost(
        languageCode,
        page,
        formSearch,
        startDate,
        endDate,
        tagIds,
        navigate,
        seletedCategory
      )
    );
  };

  useEffect(() => {
    getParentCategory();
    fetchDataTags();
  }, []);

  return (
    <>
      <h2>{t("label_from_search")}</h2>

      <Form
        noValidate
        validated={validated}
        onSubmit={(e) => handleSubmit(e)}
        onKeyDown={(e) => {
          if (e.key === "Enter") {
            e.preventDefault();
            handleSubmit(e);
          }
        }}
        className="d-flex search-results"
      >
        <Form.Group controlId="validationCustom05" style={{ width: "100%" }}>
          <Form.Control
            autoFocus
            size="md"
            className="me-2"
            type="text"
            aria-label="Search"
            name="name"
            placeholder={t("search_keyword")}
            value={formSearch}
            // onKeyDown={(e) => handleSubmit(e.target.value)}
            onChange={(e) => setFormSearch(e.target.value)}
          />

          <div className="content-filter">
            <div className="content-main-sidebar content-start-end-date row">
              <div className="date-picker startDate">
                <Form.Group controlId="startDatePicker">
                  <p className="label-filter-date">{t("start_date")}</p>
                  <Form.Control
                    required={validatedEndDate}
                    type="date"
                    name="datepic"
                    placeholder="dd/mm/yyyy"
                    value={startDate}
                    onChange={handleChangeStartDate}
                    style={{ margin: 0 }}
                  />
                </Form.Group>

                <Form.Group controlId="formattedStartDate">
                  <Form.Control
                    required={validatedEndDate}
                    name="datepic"
                    placeholder="dd/mm/yyyy"
                    type="text"
                    className="form-controls display-date-selected"
                    value={formattedStartDate} // Use formattedDate for display
                    readOnly // Ensure the field is read-only
                  />
                </Form.Group>
                <div>{/* Use the FormattedDate component */}</div>
              </div>

              <div className="date-picker endDate">
                <Form.Group controlId="endDatePicker">
                  <p className="label-filter-date">{t("end_date")}</p>
                  <Form.Control
                    required={validatedStartDate}
                    type="date"
                    name="datepic"
                    placeholder="dd/mm/yyyy"
                    value={endDate}
                    onChange={handleChangeEndDate}
                    style={{ margin: 0 }}
                  />
                </Form.Group>

                <Form.Group controlId="formattedEndDate">
                  <Form.Control
                    required={validatedStartDate}
                    name="datepic"
                    placeholder="dd/mm/yyyy"
                    type="text"
                    className="form-controls display-date-selected"
                    value={formattedEndDate} // Use formattedDate for display
                    readOnly // Ensure the field is read-only
                  />
                </Form.Group>
              </div>
            </div>
          </div>

          {validate_Start_End_Date && (
            <Form.Control.Feedback type="invalid" style={{ display: "block" }}>
              {t("validate_date_filter")}
            </Form.Control.Feedback>
          )}
        </Form.Group>
        <div className="height-between" style={{ height: 30 }}></div>
      </Form>

      <div className="content-filter">
        <div className="content-main-sidebar page-searchForm">
          <NavDropdown title={t("tag")} className="tages-dropdown-items">
            {listTags && listTags.count > 0 ? (
              listTags.results.map((resTags) => {
                return (
                  <React.Fragment key={resTags.id}>
                    <Form.Check
                      type="checkbox"
                      label={resTags.name}
                      checked={tagIds.includes(resTags.id)}
                      onChange={() => handleTagsChange(resTags.id)}
                    />
                  </React.Fragment>
                );
              })
            ) : (
              <Dropdown.Item disabled>{t("category_empty")}</Dropdown.Item>
            )}
          </NavDropdown>

          <NavDropdown
            className="main-categories-items"
            title={
              seletedCategory
                ? seletedCategory.replace(/-/g, " ")
                : t("select_category")
            }
            id="dropdown-category"
            onSelect={(eventKey) => handleSelectedCategory(eventKey)}
          >
            {listCategory && listCategory.count > 0 ? (
              listCategory.results.map((resCategories) => {
                return (
                  <React.Fragment key={resCategories.id}>
                    <NavDropdown.Item eventKey={resCategories.slug}>
                      <AiFillCaretRight size={11} color="#767676" />
                      {resCategories.name}
                    </NavDropdown.Item>
                  </React.Fragment>
                );
              })
            ) : (
              <Dropdown.Item disabled>{t("category_empty")}</Dropdown.Item>
            )}
          </NavDropdown>
        </div>
      </div>

      <div className="but-search">
        <Button onClick={handleSubmit} variant="none">
          {t("research")}
        </Button>
      </div>
    </>
  );
}
