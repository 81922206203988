import React from "react";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import "../assets/styles/skeleton.css";

const SkeletonSlide = ({ height, width, hiddenElement, countsArray }) => {
  return (
    <SkeletonTheme baseColor="#E3E3E3" highlightColor="#BDBDBD">
      <ul
        className="list"
        style={{ justifyContent: countsArray > 0 ? "space-between" : null }}
      >
        {Array(countsArray)
          .fill()
          .map((item, index) => (
            <li style={{width:width, height:height}} className="image-block full-width image-block" key={index}>
              <div>
                <p className="card-image">
                  <Skeleton height={height}/>
                </p>
              </div>
              {!hiddenElement ? (
                <div style={{ paddingBottom: 10, marginTop: 10 }}>
                  <p className="card-related-title">
                    <Skeleton height={15} width={100} />
                  </p>
                </div>
              ) : null}
            </li>
          ))}
      </ul>
    </SkeletonTheme>
  );
};

export default SkeletonSlide;
