
import React, { useEffect, useRef } from "react";
import Breadcrumbs from "../../components/breadcrumb";
import MainSidebar from "../../components/mainSidebar";
import ActivitiesCardList from "../../components/royalActivities/cardList";
import InfiniteScroll from "../../components/InfiniteScroll";
import { useDispatch, useSelector } from "react-redux";
import { fetchDataBMD, fetchDataBMDFilter, setCurrentBMDPage } from "../../redux/action";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";

export default function ActiviesRoyal({ path, categoryLabelName }) {
  const location = useLocation();
  const categorySlug = path.replace(/\s+/g, "-");
  const searchParams = new URLSearchParams(location.search);
  const searchCategory = searchParams.get("category");
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { data, isLoading, error, currentPage } = useSelector((state) => state.bmd || {});

  const { filters } = useSelector((state) => state);
  const { languageCode } = useSelector((state) => state.language);
  const containerRef = useRef();
  const dataRef = useRef(data);

  useEffect(() => {
    dataRef.current = data; // Update the ref when data changes
  }, [data]);

  const renderData = () => {
    return <ActivitiesCardList resItem={data} countCols={2} title_type={'title_bmd'} />;
  };

  const handleScroll = () => {
    const container = containerRef.current;
    if (!container || !dataRef.current) {
      return;
    }
    const bottom = container.scrollTop + container.clientHeight >= container.scrollHeight - 1;
    if (
      bottom &&
      !isLoading &&
      dataRef.current?.length >= currentPage * 12
    ) {
      const nextPage = currentPage + 1;
      dispatch(setCurrentBMDPage(nextPage));
    }
  };

  useEffect(() => {
    const container = containerRef.current;
    if (!container) {
      return;
    }

    container.addEventListener("scroll", handleScroll);
    return () => {
      container.removeEventListener("scroll", handleScroll);
    };
  }, [containerRef, handleScroll]);

  useEffect(() => {
    dispatch(fetchDataBMD(
      languageCode,
      searchCategory === null ? categorySlug : searchCategory,
      filters.day,
      filters.month,
      filters.tag_id,
      filters.year,
      currentPage
    )).then((response) => {
      if (response.data.results && response.data.results.length === 0) {
        const container = containerRef.current;
        container.removeEventListener("scroll", handleScroll);
      } else {
        // Scroll the container back to the top
        if (currentPage === 1) {
          const container = containerRef.current;
          container.scrollTop = 0;
        }
      }
    }).catch((error) => {
      console.error("Error in fetching data:", error.message);
    })

  }, [
    currentPage,
    languageCode,
    filters.day,
    filters.month,
    filters.tag_id,
    filters.year,
  ]);

  return (
    <div className="main-wrapper d-lg-flex list-unstyled image-block px-lg-0 mx-lg-0">
      <div className="sidebar-container">
        <div className="main-sidebar">
          <MainSidebar path={path} categoryLabelName={categoryLabelName} />
        </div>
      </div>
      <div className="page-container">
        <div className="main rotate-list-view">
          <div className="group-content-post">
            <Breadcrumbs breadcrumbName={t("BMD")} />
            <div>
              <InfiniteScroll
                renderData={renderData}
                isLoading={isLoading}
                fetchMoreData={data}
                currentPage={currentPage}
                error={error}
                containerRef={containerRef}
                countCols={3}
                valueMargin={10}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}







// import React, { useState, useEffect, useRef } from "react";
// import Breadcrumbs from "../../components/breadcrumb";
// import MainSidebar from "../../components/mainSidebar";
// import { FaFilePdf } from "react-icons/fa";
// import _ from "lodash";
// import { useDispatch, useSelector } from "react-redux";
// import {
//   fetchDataBMD,
//   setCurrentBMDPage,
// } from "../../redux/action";
// import InfiniteScroll from "../../components/InfiniteScroll";
// import EmptyData from "../../components/royalActivities/emptyData";
// import { useTranslation } from "react-i18next";
// import { useLocation } from "react-router-dom";

// export default function BMD({ path }) {
//   const location = useLocation();
//   const categorySlug = path.replace(/\s+/g, "-");
//   const searchParams = new URLSearchParams(location.search);
//   const searchCategory = searchParams.get("category");
//   const { t } = useTranslation();
//   const dispatch = useDispatch();
//   const { data, isLoading, error, currentPage } = useSelector(
//     (state) => state.bmd
//   );
//   const { languageCode, filters } = useSelector((state) => state);

//   const chunked = _.chunk(data, 3);
//   const containerRef = useRef();
//   const dataRef = useRef(data);

//   useEffect(() => {
//     dataRef.current = data; // Update the ref when data changes
//   }, [data]);

//   const renderData = () => {
//     return data.length > 0 ? (
//       chunked.map((i, index) => (
//         <div className="row-BMD" key={index}>
//           {!isLoading &&
//             i.map((resItem, resIndex) => (
//               <div key={resIndex} className="col-BMD">
//                 <div>
//                   <a
//                     href={resItem.pdf_file}
//                     target="_blank"
//                     rel="noopener noreferrer"
//                   >
//                     <div className="list-Items d-lg-flex align-items-center">
//                       <div>
//                         <FaFilePdf size={40} color="#032EA1" />
//                       </div>
//                       <h4>{resItem.name}</h4>
//                     </div>
//                   </a>
//                 </div>
//               </div>
//             ))}
//         </div>
//       ))
//     ) : (
//       <EmptyData />
//     );
//   };

//   const handleScroll = debounce(() => {
//     const container = containerRef.current;
//     if (!container || !dataRef.current) {
//       return;
//     }
//     const bottom =
//       container.scrollTop + container.clientHeight >=
//       container.scrollHeight - 1;
//     if (
//       bottom &&
//       currentPage < 2 &&
//       !isLoading &&
//       dataRef.current?.length > 14
//     ) {
//       const nextPage = currentPage + 1;
//       dispatch(setCurrentBMDPage(nextPage));
//     }
//   }, 100);

//   useEffect(() => {
//     const container = containerRef.current;
//     if (!container) {
//       return;
//     }

//     container.addEventListener("scroll", handleScroll);
//     return () => {
//       container.removeEventListener("scroll", handleScroll);
//     };
//   }, [containerRef, handleScroll]);

//   useEffect(() => {
//     dispatch(
//       fetchDataBMD(
//         languageCode,
//         searchCategory === null ? categorySlug : searchCategory,
//         filters.day,
//         filters.month,
//         filters.tag_id,
//         filters.years,
//         currentPage
//       )
//     )
//       .then((response) => {
//         if (response.data.results && response.data.results.length === 0) {
//           const container = containerRef.current;
//           container.removeEventListener("scroll", handleScroll);
//         }
//       })
//       .catch((error) => {
//         console.error("Error in fetching data:", error.message);
//       });
//   }, [
//     currentPage,
//     languageCode,
//     filters.day,
//     filters.month,
//     filters.tag_id,
//     filters.years,
//   ]);

//   return (
//     <div className="main-wrapper d-lg-flex list-unstyled image-block px-lg-0 mx-lg-0">
//       <div className="sidebar-container">
//         <div className="main-sidebar">
//           <MainSidebar path={path} />
//         </div>
//       </div>
//       <div className="page-container">
//         <div className="main">
//           <div className="group-content-post">
//             <Breadcrumbs breadcrumbName={t("BMD")} />
//             <div>
//               <InfiniteScroll
//                 renderData={renderData}
//                 isLoading={isLoading}
//                 fetchMoreData={data}
//                 currentPage={currentPage}
//                 error={error}
//                 containerRef={containerRef}
//                 valueMargin={19}
//               />
//             </div>
//           </div>
//         </div>
//       </div>
//     </div>
//   );
// }
