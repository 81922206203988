import React from "react";
import { Image } from "react-bootstrap";
import Slider from "react-slick";

export default function Carousel({ settings, items }) {
  return (
    // <div>
      <Slider {...settings}>
        {items.length > 0 &&
          items.map((res, index) => (
            <div key={index} className="item-carousel">
              <div className="shadow-wrapper"></div>
              <Image
                alt={res.name}
                className="d-block w-100 rounded"
                src={res.image}
                alt="1000X1000"
              />
            </div>
          ))}
      </Slider>
    // </div>
  );
}
