// config.js

const config = {
  development: {
    apiUrl: "http://localhost:3000",
  },
  production: {
    apiUrl: "http://royal.spiderweb.live",
  },
};

export default config;
