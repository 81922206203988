import React from "react";
import "./assets/styles/main.css";
import "./assets/styles/mobile.css";
import "./assets/styles/skeleton.css";
import "../node_modules/bootstrap/dist/css/bootstrap.min.css";
import { useRoutes } from "react-router-dom";
import ActiviesRoyal from "./pages/activities";
import HomePage from "./pages/home";
import Layout from "./components/layout";
import ErrorPage from "./pages/errorPage";
import Contact from "./pages/contact";
import RoyalFamily from "./pages/royalFamily";
import RoyalFamilyDetail from "./pages/royalFamily/detail";
import Media from "./pages/madia";
import BMD from "./pages/BMD";
import Messages from "./pages/messages";
import Correspondance from "./pages/correspondence";
import Documents from "./pages/documents";
import PhotosSouveir from "./pages/photoSouvenir";
import Histories from "./pages/histories";
import SearchResults from "./pages/resultSearch";
import PostDetail from "./pages/postDetail";
import Tags from "./pages/tag";
import { useTranslation } from "react-i18next";

export default function App() {
  const {t} = useTranslation();
  const element = useRoutes([
    {
      path: "/",
      element: <Layout />,
      errorElement: <ErrorPage />,
      children: [
        {
          path: "/*",
          element: <ErrorPage />,
        },
        {
          path: "/",
          element: <HomePage />,
        },
        {
          path: "/contact",
          element: <Contact />,
        },
        // {
        //   path: "/articles/:id",
        //   element: <PostDetail />,
        // },
        {
          path: "/media",
          children: [
            { index: true, element: <Media path='media' categoryLabelName={t('category_media')}/> },
            { path: "articles/:id", element: <PostDetail /> },
          ],
          // element: <Media path='media' categoryLabelName={t('category_media')}/>,
        },
        {
          path: "/bmd",
          children: [
            { index: true, element: <BMD path='bmd' categoryLabelName={t('category_bmd')}/> },
            { path: "articles/:id", element: <PostDetail /> },
          ],
          // element: <BMD path='bmd' categoryLabelName={t('category_bmd')}/>,
        },
        {
          path: "/messages",
          children: [
            { index: true, element: <Messages path='messages' categoryLabelName={t('category_messages')}/> },
            { path: "articles/:id", element: <PostDetail /> },
          ],
          // element: <Messages path='messages' categoryLabelName={t('category_messages')}/>,
        },
        {
          path: "/souvenir-photos",
          children: [
            { index: true, element: <PhotosSouveir path='souvenir photos' categoryLabelName={t('category_souvenir_photos')}/> },
            { path: "articles/:id", element: <PostDetail /> },
          ],
          // element: <PhotosSouveir path='souvenir photos' categoryLabelName={t('category_souvenir_photos')}/>,
        },
        {
          path: "/correspondence",
          children: [
            { index: true, element: <Correspondance path='correspondence' categoryLabelName={t('category_correspondence')}/> },
            { path: "articles/:id", element: <PostDetail /> },
          ],
          // element: <Correspondance path='correspondence' categoryLabelName={t('category_correspondence')}/>,
        },
        {
          path: "/documents",
          children: [
            { index: true, element: <Documents path='documents' categoryLabelName={t('category_documents')}/> },
            { path: "articles/:id", element: <PostDetail /> },
          ],
          // element: <Documents path='documents' categoryLabelName={t('category_documents')}/>,
        },
        {
          path: "/history",
          children: [
            { index: true, element: <Histories path='history' categoryLabelName={t('category_history')}/> },
            { path: "articles/:id", element: <PostDetail /> },
          ],
          // element: <Histories path='history' categoryLabelName={t('category_history')}/>,
        },
        {
          path: "/search-results",
          children: [
            { index: true, element: <SearchResults /> },
            { path: "articles/:id", element: <PostDetail /> },
          ],
          // element: <SearchResults />,
        },
        {
          path: "/tags",
          children: [
            { index: true, element: <Tags /> },
            { path: "articles/:id", element: <PostDetail /> },
          ],
          // element: <Tags />,
        },
        {
          path: "/royal-family",
          children: [
            { index: true, element: <RoyalFamily /> },
            { path: ":id", element: <RoyalFamilyDetail /> },
          ],
        },
        {
          path: "/royal-activities",
          children: [
            { index: true, element: <ActiviesRoyal path='royal activities' categoryLabelName={t('royal_activities')}/> },
            { path: "articles/:id", element: <PostDetail /> },
          ],
          // element: <ActiviesRoyal path='royal activities' categoryLabelName={t('royal_activities')}/>,
        },
      ],
    },
  ]);

  return element;
}
