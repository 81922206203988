import React, { useState, useEffect, useRef, useCallback } from "react";
import { Row, Col } from "react-bootstrap";
import CarouselSouvenirPhotos from "../../components/carousel";
import InfiniteScroll from "../../components/InfiniteScroll";
import { useDispatch, useSelector } from "react-redux";
import { fetchDataPost, setCurrentBMDPage, setCurrentPage } from "../../redux/action";
import ActivitiesCardList from "../../components/royalActivities/cardList";
import axiosInstance from "../../context/axios";
import { useTranslation } from "react-i18next";
import SkeletonSlide from "../../components/skeletonSlide";

export default function HomePage() {
  const dispatch = useDispatch();

  const { data, isLoading, error, currentPage } = useSelector((state) => state.posts.recentNews || {});

  // const { languageCode } = useSelector((state) => state);
  const { languageCode } = useSelector((state) => state.language);
  const { t } = useTranslation();
  const [slideType, setSlideType] = useState([]);
  const [dataSlide, setDataSlide] = useState([]);
  const [slideLoading, setSlideLoading] = useState(true)
  const containerRef = useRef();
  const dataRef = useRef(data);

  useEffect(() => {
    dataRef.current = data; // Update the ref when data changes
  }, [data]);

  const resultType = slideType.reduce((acc, type) => {
    const slidesOfType = dataSlide.filter((slide) => slide.type === type.id);
    acc.push({
      type: type.name,
      slides: slidesOfType,
    });
    return acc;
  }, []);

  const newData = slideType.map((resultItem) => {
    const matchedType = resultType.find(
      (resultType) => resultType.type === resultItem.name
    );
    if (matchedType) {
      return {
        type: matchedType.type,
        slides: matchedType.slides,
      };
    }
    return null;
  });

  // Filter out null values (entries without matching types)
  const filteredData = newData.filter((entry) => entry !== null);

  const handleScroll = () => {
    const container = containerRef.current;
    if (!container || !dataRef.current) {
      return;
    }
    const bottom = container.scrollTop + container.clientHeight >= container.scrollHeight - 1;
    if (
      bottom &&
      !isLoading &&
      dataRef.current?.length >= currentPage * 12
    ) {
      const nextPage = currentPage + 1;
      dispatch(setCurrentPage(nextPage));
      dispatch(setCurrentBMDPage(nextPage));
    }
  };

  useEffect(() => {
    const container = containerRef.current;
    if (!container) {
      return;
    }

    container.addEventListener("scroll", handleScroll);
    return () => {
      container.removeEventListener("scroll", handleScroll);
    };
  }, [containerRef, handleScroll])

  useEffect(() => {
    getDataSliderItems();
    dispatch(fetchDataPost('recentNews', languageCode, currentPage, {}))
      .then((response) => {
        if (response.data.results.length === 0) {
          const container = containerRef.current;
          container.removeEventListener("scroll", handleScroll);
        } else {
          // Scroll the container back to the top
          if (currentPage === 1) {
            const container = containerRef.current;
            container.scrollTop = 0;
          }
        }
      })
      .catch((error) => {
        console.error("Error in fetching data:", error.message);
      });
  }, [dispatch, languageCode, currentPage]);

  const getDataSliderItems = async () => {
    try {
      await axiosInstance.get("/api/slide/slides/").then((resResponse) => {
        setDataSlide(resResponse.data.results);
        setSlideLoading(false);
      });
    } catch (error) {
      console.log(error);
    }
  };

  var settings = {
    dots: true,
    infinite: true,
    speed: 1000,
    autoplaySpeed: 5500,
    autoplay: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    cssEase: "linear",
    arrows: false,
    variableWidth: false,
    className: "slider variable-width-photo-souvenir",
  };

  const renderData = useCallback(() => {
    return <ActivitiesCardList resItem={data} countCols={4} />;
  }, [data]);

  return (
    <div className="text-center">
      <Row>
        <Col md={5} style={{ paddingRight: 0 }}>
          <div className="owl-slider">
            <div id="carousel" className="owl-carousel">
              {dataSlide && dataSlide.map((res) => {
                if (res.type === "Souvenirs Photo Slide") {
                  return (
                    slideLoading ? (
                      <div style={{ marginTop: 25 }}><SkeletonSlide height={300} width={530} /></div>
                    ) : (
                      <CarouselSouvenirPhotos
                        key={res.type}
                        settings={settings}
                        items={res.images}
                        slideType={slideType}
                      />
                    )
                  );
                }
                return null;
              })}
            </div>
          </div>
          <div className="valueVisited">
            <h5 className="lableVisited">Visiteurs 18366311</h5>
            {/* <VisitCounter></VisitCounter> */}
          </div>
        </Col>
        <Col md={7} className="content-hot-news">
          <h1 className="hot-news">{t("hot_news")}</h1>
          {/* <div className="pre-scrollables"> */}
          <ul className="d-lg-flex list-unstyled image-block px-lg-0 mx-lg-0">
            <InfiniteScroll
              renderData={renderData}
              isLoading={isLoading}
              fetchMoreData={data}
              currentPage={currentPage}
              error={error}
              containerRef={containerRef}
              countCols={4}
              valueMargin={10}
            />
          </ul>
          {/* </div> */}
        </Col>
      </Row>
    </div>
  );
}