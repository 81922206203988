// i18n.js
import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import translations from "./translations/translationsIndex";

const languageDetector = new LanguageDetector(null, {
  // Optional: Define the order in which language detection methods should be used
  order: ["cookie"],

  // Optional: Configure cookie options
  lookupCookie: "lang", // Use the 'lang' cookie for language detection
});

i18n
  .use(languageDetector)
  .use(initReactI18next)
  .init({
    fallbackLng: "fr", // Fallback language in case the detected language is not available
    resources: translations,
    interpolation: {
      escapeValue: false, // React already escapes values by default
    },
  });

export default i18n;
