// LanguageSelector.js
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setLanguage } from "../redux/action";
import { useTranslation } from "react-i18next";
import { Dropdown, Button, Form } from "react-bootstrap";

const LanguageSelector = () => {
  const dispatch = useDispatch();
  const { languageCode } = useSelector((state) => state.language);
  const { i18n } = useTranslation();

  useEffect(() => {
    // Retrieve language code from the 'lang' cookie when the component mounts
    const langCookie = getCookie("lang");
    if (langCookie) {
      dispatch(setLanguage(langCookie));
    }
    i18n.changeLanguage(langCookie);
  }, [dispatch, i18n]);

  const handleLanguageChange = (selectedLanguage) => {
    // const newLanguageCode = event.target.value;
    dispatch(setLanguage(selectedLanguage));

    // Set the language code in a cookie
    document.cookie = `lang=${selectedLanguage}; path=/`;
    window.location.reload();
  };

  const availableLanguages = ["en", "fr", "km"];

  const getCookie = (name) => {
    const cookieValue = document.cookie
      .split("; ")
      .find((cookie) => cookie.startsWith(`${name}=`));

    return cookieValue ? cookieValue.split("=")[1] : null;
  };

  return (
    <Dropdown onSelect={(eventKey) => handleLanguageChange(eventKey)}>
      <Dropdown.Toggle variant="none" id="dropdown-language">
        {languageCode === "en"
          ? "English"
          : languageCode === "fr"
          ? "French"
          : languageCode === "km"
          ? "Khmer"
          : languageCode}
      </Dropdown.Toggle>
      <Dropdown.Menu className="dropdown-languages">
        {availableLanguages.map((lang) => (
          <Dropdown.Item key={lang} eventKey={lang} className={lang === languageCode ? 'active': ''}>
            {lang === "en"
              ? "English"
              : lang === "fr"
              ? "French"
              : lang === "km"
              ? "Khmer"
              : lang}
          </Dropdown.Item>
        ))}
      </Dropdown.Menu>
    </Dropdown>
  );
};

export default LanguageSelector;
