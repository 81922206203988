import Breadcrumb from "react-bootstrap/Breadcrumb";
import { MdOutlineKeyboardDoubleArrowRight } from "react-icons/md";
import { TiHome } from "react-icons/ti";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { setCurrentPage } from "../redux/action";

export default function Breadcrumbs(props) {
  const dispatch = useDispatch();
  const handleClearCurrentPageState = ({page= 1}) => {
    dispatch(setCurrentPage(page));
  }

  return (
    <Breadcrumb>
      <Breadcrumb.Item>
        <Link onClick={handleClearCurrentPageState} to="/">
          <TiHome />
        </Link>
      </Breadcrumb.Item>

      {props.breadcrumb === undefined ? (
        <>
          <MdOutlineKeyboardDoubleArrowRight />
          <Breadcrumb.Item active>
            {props.breadcrumbRoyalFamily !== undefined ? (
              <Link to={`/${props.slug}`}>{props.breadcrumbName}</Link>
            ) : (
              props.breadcrumbName
            )}
          </Breadcrumb.Item>
          {props.breadcrumbRoyalFamily !== undefined && (
            <>
              <MdOutlineKeyboardDoubleArrowRight />
              <Breadcrumb.Item active>
                {props.breadcrumbRoyalFamily}
              </Breadcrumb.Item>
            </>
          )}
        </>
      ) : (
        <>
          <MdOutlineKeyboardDoubleArrowRight />
          {props.breadcrumb.category &&
          props.breadcrumb.category.parent.parent ? (
            <Breadcrumb.Item active>
              {props.breadcrumb.category && props.breadcrumb.category.parent ? (
                <Link
                  to={`/${props.breadcrumb.category.parent.parent.slug}/?category=${props.breadcrumb.category.parent.slug}`}
                >
                  {props.breadcrumb.category.parent.name}
                </Link>
              ) : (
                "Default Name"
              )}
            </Breadcrumb.Item>
          ) : (
            <Breadcrumb.Item active>
              {props.breadcrumb.category && props.breadcrumb.category.parent ? (
                <Link to={`/${props.breadcrumb.category.parent.slug}`}>
                  {props.breadcrumb.category.parent.name}
                </Link>
              ) : (
                "Default Name"
              )}
            </Breadcrumb.Item>
          )}
        </>
      )}

      {props.breadcrumb === undefined ? null : (
        <>
          <MdOutlineKeyboardDoubleArrowRight />
          {/* <Breadcrumb.Item active>
            {props.breadcrumb.category && props.breadcrumb.category.parent ? (
              <Link
                to={`/${props.breadcrumb.category.parent.slug}/?category=${props.breadcrumb.category.slug}`}
              >
                {props.breadcrumb.category.name}
              </Link>
            ) : (
              "Default Name"
            )}
          </Breadcrumb.Item> */}
          {props.breadcrumb.category &&
          props.breadcrumb.category.parent.parent ? (
            <Breadcrumb.Item active>
              {props.breadcrumb.category && props.breadcrumb.category.parent ? (
                <Link onClick={handleClearCurrentPageState}
                  to={`/${props.breadcrumb.category.parent.parent.slug}/?category=${props.breadcrumb.category.slug}`}
                >
                  {props.breadcrumb.category.name}
                </Link>
              ) : (
                "Default Name"
              )}
            </Breadcrumb.Item>
          ) : (
            <Breadcrumb.Item active>
              {props.breadcrumb.category && props.breadcrumb.category.parent ? (
                <Link onClick={handleClearCurrentPageState}
                  to={`/${props.breadcrumb.category.parent.slug}/?category=${props.breadcrumb.category.slug}`}
                >
                  {props.breadcrumb.category.name}
                </Link>
              ) : (
                "Default Name"
              )}
            </Breadcrumb.Item>
          )}
        </>
      )}

      {props.breadcrumb === undefined ? null : (
        <>
          <MdOutlineKeyboardDoubleArrowRight />
          <Breadcrumb.Item active className="last-active">
            {props.breadcrumb && props.breadcrumb
              ? props.breadcrumb.name
              : "Default Name"}
          </Breadcrumb.Item>
        </>
      )}
    </Breadcrumb>
  );
}
