import React from "react";
import { Helmet } from "react-helmet";

const MetaHelmet = ({ title, description, url, image }) => {

  return (
    <Helmet>
      <title>{title}</title>
      <meta name="description" content={description} />
      <meta name="keywords" content="King-Father, Norodom Sihanouk, Cambodia, Royal Palace, Roi-Père Norodom, Cambodge, Norodomsihanouk" />
      <meta property="og:title" content={title} />
      <meta property="og:description" content={description} />
      <meta property="og:image" content={image} />
      <meta property="og:url" content={url} />
      <meta property="og:type" content="article" />
      <script type="application/ld+json">
        {JSON.stringify({
          "@context": "https://www.norodomsihanouk.info/",
          "@type": "Article",
          "headline": title,
          "image": image,
         
          "publisher": {
            "@type": "",
            "name": "",
            "logo": {
              "@type": "ImageObject",
              "url": "https://admin.norodomsihanouk.info/media/slides/Royal_305195.jpg"
            }
          },
          "datePublished": new Date().toISOString(),
          "description": description,
          "mainEntityOfPage": {
            "@type": "WebPage",
            "@id": url
          }
        })}
      </script>
    </Helmet>
  );
};

export default MetaHelmet;
