import { configureStore, getDefaultMiddleware } from '@reduxjs/toolkit';
import thunk from 'redux-thunk'; // Importing thunk middleware if needed
import reducers from './reducers'; // Adjust the import based on your reducers file

// Add any middleware if needed (e.g., thunk)
const middleware = [thunk];

const store = configureStore({
  reducer: reducers,
  middleware: [...getDefaultMiddleware(), ...middleware],
});

export default store;
