import React from "react";
import { Image } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import EmptyDataImage from "../../assets/Images/emptyData.png";

export default function EmptyData() {
  const { t } = useTranslation();
  return (
    <div className="empty-display-page">
      <Image
        style={{
          width: 130,
          height: 130,
          background: "#f6f6f6",
          borderRadius: 100,
          padding: 20,
          boxShadow: "0px 3px 4px 0px #0404041a",
        }}
        src={EmptyDataImage}
      />
      <div style={{ marginTop: 10 }}>
        <h2>Oops!</h2>
        <p>{t("no_data_found")}</p>
      </div>
    </div>
  );
}
