// reducer.js

import {
  FETCH_DATA_ROYAL_FAMILY_REQUEST,
  FETCH_DATA_ROYAL_FAMILY_SUCCESS,
  FETCH_DATA_ROYAL_FAMILY_FAILURE,

  FETCH_DATA_POST_REQUEST,
  FETCH_DATA_POST_SUCCESS,
  FETCH_DATA_POST_FAILURE,

  FETCH_DATA_POST_SEARCH_REQUEST,
  FETCH_DATA_POST_SEARCH_SUCCESS,
  FETCH_DATA_POST_SEARCH_FAILURE,
  
  FETCH_DATA_BMD_REQUEST,
  FETCH_DATA_BMD_SUCCESS,
  FETCH_DATA_BMD_FAILURE,

} from './actionTypes';
import {initialState} from './initialState';

const reducers = (state = initialState, action) => {
  switch (action.type) {
    //reset on Filter post
    case 'RESET_FILTERS':
      return {
        ...state,
        filters: {
          // Reset your filters to their initial state
          // Define your initial filter state here
        },
      };


  //Case type lang Code
    case 'SET_LANGUAGE':
      return {
        ...state,
        language: {
          ...state.language,
          languageCode: action.payload,
        },
      };
    
    case "SET_CURRENT_POST_PAGE":
      return {
        ...state,
        posts: {
          ...state.posts,
          tags: {
            ...state.posts.tags,
            currentPage: action.payload,
          },
          recentNews: {
            ...state.posts.recentNews,
            currentPage: action.payload,
          },
          royalActivities: {
            ...state.posts.royalActivities,
            currentPage: action.payload,
          },
          correspondence: {
            ...state.posts.correspondence,
            currentPage: action.payload,
          },
          souvenirPhotos: {
            ...state.posts.souvenirPhotos,
            currentPage: action.payload,
          },
          history: {
            ...state.posts.history,
            currentPage: action.payload,
          },
          media: {
            ...state.posts.media,
            currentPage: action.payload,
          },
          documents: {
            ...state.posts.documents,
            currentPage: action.payload,
          },
          messages: {
            ...state.posts.messages,
            currentPage: action.payload,
          },
          // Add other sections as needed
        },
      };

    case "SET_CURRENT_BMD_PAGE":
      return {
        ...state,
        bmd: {
          ...state.bmd,
          currentPage: action.payload,
        },
        
      };

    case "SET_CURRENT_SEARCH_POST_PAGE":
      return {
        ...state,
        searchPosts: {
          ...state.searchPosts,
          currentPage: action.payload,
        },
        
      };
  
    case "SET_CURRENT_ROYALFAMILY_PAGE":
      return {
        ...state,
        royalFamily: {
          ...state.royalFamily,
          currentPage: action.payload,
        },
      };


    //Case type Royal Family
    case FETCH_DATA_ROYAL_FAMILY_REQUEST:
      return {
        ...state,
        royalFamily: {
          ...state.royalFamily,
          isLoading: true,
          error: null,
        },
      };
    case FETCH_DATA_ROYAL_FAMILY_SUCCESS:
      const uniqueNewDataRoyal = action.payload.data.filter(
        (newItem) => !state.royalFamily.data.some((existingItem) => existingItem.id === newItem.id)
      );
      const newStateRoyal = action.payload.currentPage === 1 ? action.payload.data : [...state.royalFamily.data, ...uniqueNewDataRoyal]
      return {
        ...state,
        royalFamily: {
          ...state.royalFamily,
          data: newStateRoyal,
          isLoading: false,
          error:null,
          currentPage: action.payload.currentPage,
        },
      };
    case FETCH_DATA_ROYAL_FAMILY_FAILURE:
      return {
        ...state,
        royalFamily: {
          ...state.royalFamily,
          isLoading: false,
          error: action.payload,
        }
      };


    //Case type Posts
    case FETCH_DATA_POST_REQUEST:
      return {
        ...state,
        posts: {
          ...state.posts,
          [action.payload.postType]: {
            ...state.posts[action.payload.postType],
            isLoading: true,
            error: null,
          }
        }
      };
      case FETCH_DATA_POST_SUCCESS:
        if (!Array.isArray(action.payload.data.results)) {
          return state;
        }
        const newData = action.payload.data.results;
      
        // Check if state.posts[action.payload.postType].data is iterable (an array)
        const existingData = state.posts[action.payload.postType]?.data || [];
      
        const uniqueNewDataPosts = newData.filter(
          (newItem) => !existingData.some((existingItem) => existingItem.id === newItem.id)
        );
      
        const newDataState = action.payload.currentPage === 1 ? newData : [...existingData, ...uniqueNewDataPosts];
        return {
          ...state,
          posts: {
            ...state.posts,
            [action.payload.postType]: {
              ...state.posts[action.payload.postType],
              data: newDataState,
              error: null,
              currentPage: action.payload.currentPage,
              isLoading: false,
            },
          },
          filters: action.payload.filters || {},
        };
      

    case FETCH_DATA_POST_FAILURE:
      return {
        ...state,
        posts: {
          ...state.posts,
          [action.payload.postType]: {
            ...state.posts[action.payload.postType],
            isLoading: false,
            error: action.payload.error,
          }
        }
      };
      
     //Case type BMD
    case FETCH_DATA_BMD_REQUEST:
    return {
      ...state,
      bmd:{
        ...state.bmd,
        isLoading: true,
        error: null,
      }
    };
    case FETCH_DATA_BMD_SUCCESS:

      const uniqueNewDatabmd = action.payload.data.filter(
        (newItem) => !state.bmd.data.some((existingItem) => existingItem.id === newItem.id)
      );
      const newStatebmd = action.payload.currentPage === 1 ? action.payload.data : [...state.bmd.data, ...uniqueNewDatabmd];

      return {
        ...state,
        bmd:{
          ...state.bmd,
          data: newStatebmd,
          error:null,
          currentPage: action.payload.currentPage,
          isLoading: false,
        },
        filters: action.payload.filters || {}
      };
    case FETCH_DATA_BMD_FAILURE:
      return {
        ...state,
        bmd:{
          ...state.bmd,
          isLoading: false,
          error: action.payload,
        }
      };  

    
    case FETCH_DATA_POST_SEARCH_REQUEST:
      return {
        ...state,
        searchPosts:{
          ...state.searchPosts,
          isLoading: true,
          error: null,
        }
      };
    case FETCH_DATA_POST_SEARCH_SUCCESS:
      const uniqueNewDataSearchPosts = action.payload.data.filter(
        (newItem) => !state.searchPosts.data.some((existingItem) => existingItem.id === newItem.id)
      );
      const newStateSearchPosts = action.payload.currentPage === 1 ? action.payload.data : [...state.searchPosts.data, ...uniqueNewDataSearchPosts];

      return {
        ...state,
        searchPosts:{
          ...state.searchPosts,
          data: newStateSearchPosts,
          error:null,
          currentPage: action.payload.currentPage,
          isLoading: false,
        },
        filters: action.payload.filters || {}
      };
    case FETCH_DATA_POST_SEARCH_FAILURE:
        return {
          ...state,
          searchPosts:{
            ...state.searchPosts,
            isLoading: false,
            error: action.payload,
          }
        };  
    
    
    default:
    return state;
  }
};

export default reducers;
