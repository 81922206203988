import React, { useState, useEffect } from "react";
import Breadcrumbs from "../../components/breadcrumb";
import { useParams } from "react-router";
import { FaCalendarAlt } from "react-icons/fa";
import { Nav } from "react-bootstrap";
import SkeletonRelated from "../../components/skeletonRelated";
import axiosInstance from "../../context/axios";
import ErrorPage from "../errorPage";
import PostDetailImageGallery from "../../components/garlleryModalDailog/imageGallery";
import SkeletonCard from "../../components/skeletonCard";
import FormattedDate from "../../components/formattedDate";
import SubSidebar from "../../components/subSidebar";
import SocialShare from "../../components/socialShare";
import OtherDetail from "./otherDetail";
import { fetchDataTagPost } from "../../redux/action";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import config from "../../context/config";
import MetaHelmet from "./metaHelmet";

export default function PostDetail() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { currentPage } = useSelector((state) => state.posts.tags || {});
  const { languageCode } = useSelector((state) => state.language);
  const [loading, setLoading] = useState(false);
  const [dataPostDetail, setDataPostDetail] = useState({});
  const [error, setError] = useState(null);
  const { id: postId } = useParams();
  const apiUrl = process.env.REACT_APP_SITE_URL;

  const getCategorySlug = (category) => {
    if (!category) return null;
    if (!category.parent) return category.slug;
    if (!category.parent.parent) return category.parent.slug;
    return category.parent.parent.slug;
  };
  const slugUrl = getCategorySlug(dataPostDetail.category);

  const post = {
    url: `${apiUrl}/${slugUrl}/articles/${postId}`,
    title: dataPostDetail.name,
    description: dataPostDetail.excerpt,
    thumbnail: dataPostDetail.thumbnail,
  };

  const postDetail = async (postId) => {
    try {
      const result = await axiosInstance.get(
        `${languageCode}/api/post/posts/${postId}/`
      );
      setDataPostDetail(result.data);
      setLoading(false);
    } catch (error) {
      if (error.response && error.response.status === 404) {
        setError("404 Not Found");
      } else {
        setError("An error occurred while fetching data from the API.");
      }
    }
  };

  const handleItemClick = (tagId) => {
    dispatch(fetchDataTagPost(languageCode, 'tags', tagId, currentPage, navigate, {}));
  };

  useEffect(() => {
    setLoading(true);
    postDetail(postId);
  }, [postId]);

  if (error) {
    return <ErrorPage />;
  }

  return (
    <>
      {!loading && dataPostDetail && (
        <div className="main-wrapper d-lg-flex list-unstyled image-block px-lg-0 mx-lg-0 content-detail">

          <MetaHelmet 
            title={post.title}
            description={post.description}
            url={post.url}
            image={post.thumbnail}
          />

          <div className="sidebar-container">
            <div className="main-sidebar">
              <SubSidebar relatedItems={dataPostDetail} />
              {loading && <SkeletonRelated countCols={1} countsArray={0} />}
            </div>
          </div>
          <div className="page-container">
            <div className="main">
              <div className="group-content-post">
                <div className="postTitle">
                  <h1>{dataPostDetail.name}</h1>
                  <p className="post-excerpt">{dataPostDetail.excerpt}</p>
                </div>
                <div className="content-breadcrumb">
                  <Breadcrumbs
                    breadcrumbName={
                      dataPostDetail.category && dataPostDetail.category.name
                    }
                    breadcrumb={dataPostDetail}
                  />

                  {dataPostDetail.date ? (
                    <div className="post-date-view">
                      <FaCalendarAlt
                        size={10}
                        color="#032ea1"
                        style={{ marginRight: 5 }}
                      />
                      <FormattedDate date={dataPostDetail.date} />
                    </div>
                  ) : null}
                </div>
                <div className="post-detial">
                  {dataPostDetail.category && dataPostDetail.category.content_type === 'media' ? (
                    <OtherDetail dataPostDetail={dataPostDetail} />
                  ) : (
                    <div style={{ marginRight: 10 }} className="box-content-onscorll">
                      {loading && <SkeletonCard countCols={3} />}
                      {!loading && dataPostDetail.images && dataPostDetail.images.length > 0 ? (
                        <PostDetailImageGallery listImagesGallery={dataPostDetail.images} />
                      ) : (
                        <div className="pre-scrollables"></div>
                      )}
                    </div>
                  )}
                </div>
                <div className="post-footer-bottom">
                  <div className="content-footer-bottom d-lg-flex justify-content-between">
                    <SocialShare {...post} />
                    {dataPostDetail.tag && dataPostDetail.tag.length > 0 ? (
                      <div className="d-lg-flex post-tags align-items-center">
                        <h2>{t("tag")}</h2>
                        <Nav as="ul">
                          {dataPostDetail.tag && dataPostDetail.tag.length > 0 ? (
                            dataPostDetail.tag.map((resTags) => {
                              return (
                                <Nav.Item
                                  key={resTags.id}
                                  as="li"
                                  onClick={() => handleItemClick(resTags.id)}
                                >
                                  <Nav.Link>{resTags.name}</Nav.Link>
                                </Nav.Item>
                              );
                            })
                          ) : (
                            <Nav.Item
                              as="li"
                              style={{ fontSize: 13, paddingLeft: 5 }}
                            >
                              {t("tags_empty")}
                            </Nav.Item>
                          )}
                        </Nav>
                      </div>
                    ) : null}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}
