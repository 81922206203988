import React, { useEffect, useState } from "react";
import MainHeader from "./mainHeader";
import MainFooter from "./mainFooter";
import { Outlet } from "react-router";
import { Container } from "react-bootstrap";
const ResizableComponent = () => {
  const [contentHeight, setContentHeight] = useState(0);
  const headerHeight = 179;
  const footerHeight = 45;

  const calculateContentHeight = () => {
    const windowHeight = window.innerHeight;
    const availableHeight = windowHeight - (headerHeight + footerHeight);
    setContentHeight(availableHeight);
  };

  useEffect(() => {
    calculateContentHeight(); // Initial calculation

    const handleResize = () => {
      calculateContentHeight();
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []); // Empty dependency array ensures that effect runs only on mount and unmount

  return (
    <div
      style={{ display: "flex", flexDirection: "column", minHeight: "100vh" }}
    >
      <div
        id="header"
        style={{
          height: headerHeight + "px",
          position: "fixed",
          top: 0,
          width: "100%",
        }}
      >
        <MainHeader />
      </div>
      <div className="content-Height"
        style={{
          marginTop: headerHeight + "px",
          marginBottom: footerHeight + "px",
          height: contentHeight + "px",
          overflowY: "hidden", // Enable vertical scrolling
        }}
      >
        <Container className="content-body">
          <Outlet />
        </Container>
      </div>
      <div
        id="footer"
        style={{
          height: footerHeight + "px",
          position: "fixed",
          bottom: 0,
          width: "100%",
        }}
      >
        <MainFooter />
      </div>
    </div>
  );
};

export default ResizableComponent;
