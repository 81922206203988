// translations_km.js
const translationsKm = {
  translation: {
    welcome: "សូមស្វាគមន៍",
    research: "ស្វែងរក",
    royal_family: "ញាតិ​វង្សានុវង្ស",
    royal_activities: "ព្រះរាជសកម្មភាព",
    souvenir_photos: "រូបថតអនុស្សាវរីយ៍",
    history: "ប្រវត្តិសាស្ត្រ",
    correspondence: "ទំនាក់ទំនងលិខិត",
    BMD: "ព្រឹត្តិបត្រ",
    media: "សោតទស្សន៍",
    documents: "ឯកសារ",
    messages: "សារប្រកាស",
    contact_us: "ទំទាក់ទំនង",
    visitors: "ទស្សនា",
    slogen: "ព្រះរាជគេហទំព័រផ្លូវការរបស់ សម្តេចព្រះនរោត្តម សីហនុ នៃព្រះរាជាណាចក្រកម្ពុជា",
		privacy_policy:"គោលការណ៍​ភាព​ឯកជន",
		tag:'ស្លាក',
		related:'ពាក់ព័ន្ធ',
		share:'ចែករំលែក',
		result_found:'លទ្ធផលបានរកឃើញ',
		adresse:"អាស័យដ្ឋាន",
		adress_email:"អាសយដ្ឋានអ៊ីមែល",
		technician_support:"បច្ចេកទេស",
		phone_number:"លេខទូរស័ព្ទ",
		fax:"ទូរសារ",
		royal_palce:"ព្រះរាជខុទ្ទកាល័យ សម្តេចព្រះមហាក្សត្រី ព្រះវររាជមាតាជាតិខ្មែរ រាជធានីភ្នំពេញ ព្រះរាជាណាចក្រកម្ពុជា",
		hot_news:"ព័ត៌មានថ្មីៗ",
    no_data_found:'មិនមានទិន្នន័យ',
    category_empty:"មិនមានទិន្នន័យ",
    tags_empty:"មិនមានទិន្នន័យ",
    filter:"ស្វែងរកតាមប្រភេទ",
    label_from_search:"ស្វែងរកពាក្យគន្លឹះ",
    select_category:"ជ្រើសរើសប្រភេទ",
    search_keyword:"ស្វែងរកតាមរយៈឈ្មោះអត្ថបទ, ការពិពណ៌នា...",
    filmography:"ខ្សែភាពយន្ត",
    video:"វីដេអូ",
    read_more:"អាន​បន្ថែម",
    music: 'តន្ត្រី',
    download_music: "ទាញយកតន្ត្រី",


    category_media:"សោតទស្សន៍",
    category_bmd:"ព្រឹត្តិបត្រ",
    category_messages:"សារប្រកាស",
    category_souvenir_photos:"រូបថតអនុស្សាវរីយ៍",
    category_correspondence:"ទំនាក់ទំនងលិខិត",
    category_documents:"ឯកសារ",
    category_history:"ប្រវត្តិសាស្ត្រ",
    categroy_royal_activities:"ព្រះរាជសកម្មភាព",
    download:"ទាញយក",
    royal_family_biography: "ជីវប្រវត្តិរបស់ព្រះរាជវង្សានុវង្ស",
    validate_date_filter: "កាលបរិច្ឆេទចាប់ផ្តើមត្រូវតែតិចជាងកាលបរិច្ឆេទបញ្ចប់",
    start_date:"ថ្ងៃ​ចាប់ផ្តើម",
    end_date:"កាលបរិច្ឆេទបញ្ចប់",
    selected_year:"ជ្រើសរើសឆ្នាំ",
    selected_month:"ជ្រើសរើសខែ",
    label_meesage_validation:"សូមជ្រើសរើសតម្លៃឆ្នាំ ឬខែ ដើម្បីស្វែងរក",
    label_link_oldWeb:"ព្រះរាជគេហទំព័រចាស់",

		copy_right:"រក្សាសិទ្ធិ © 2023 Royal Cabinet, All right reserved. រចនាដោយ Giantfocus"
  },
};

export default translationsKm;
