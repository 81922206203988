import React, { useState } from 'react';
import 'react-h5-audio-player/lib/styles.css';
import AudioPlayer from 'react-h5-audio-player';
import { Button, Image, Modal } from 'react-bootstrap';
import { useTranslation } from "react-i18next";

function ImageModalDisplay(props) {
  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered >
      <Modal.Body closeButton>
        <Button variant="" onClick={() => props.onHide(false)}>
          <Image src={props.ImageLyric} className="img-responsive w-100 img-lyric" />
        </Button>
      </Modal.Body>
    </Modal>
  );
}


const CustomAudioPlayer = ({ src, onClickNext, onEnded, onError, dataPostDetail }) => {
  const { t } = useTranslation();
  const handleDownload = () => {
    const xhr = new XMLHttpRequest();
    xhr.open('GET', src, true);
    xhr.responseType = 'blob';

    xhr.onload = () => {
      if (xhr.status === 200) {
        const url = window.URL.createObjectURL(new Blob([xhr.response]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `audio.mp3`); // Change this to a dynamic filename if needed
        document.body.appendChild(link);
        link.click();
        link.parentNode.removeChild(link);
      }
    };

    xhr.onerror = () => {
      console.error('Download error');
    };

    xhr.send();
  };

  return (
    <div>
      <div className="but-download-sound">
        <Button onClick={handleDownload}>{t('download_music')}</Button>
      </div>
      <AudioPlayer
        src={src}
        showSkipControls
        onClickNext={onClickNext}
        onEnded={onEnded}
        onError={onError}
      />
    </div>
  );
};


const PlayerApp = ({ dataPostDetail }) => {
  const [currentTrack, setTrackIndex] = useState(0);
  const [modalShow, setModalShow] = useState(false);

  // const handleClickNext = () => {
  //   console.log('click next');
  //   setTrackIndex((currentTrack) =>
  //     currentTrack < playlist.length - 1 ? currentTrack + 1 : 0
  //   );
  // };

  // const handleEnd = () => {
  //   console.log('end');
  //   setTrackIndex((currentTrack) =>
  //     currentTrack < playlist.length - 1 ? currentTrack + 1 : 0
  //   );
  // };

  return (
    <div className="group-container">
      <div className="d-lg-flex">
        <div className="block-audio">

          <Image src={dataPostDetail.thumbnail} roundedCircle className="img-responsive profile-thum" />

          <CustomAudioPlayer
            src={dataPostDetail?.file_mp3[0]?.file_mp3}
            // onClickNext={handleClickNext}
            // onEnded={handleEnd}
            dataPostDetail={dataPostDetail}
            onError={() => {
              console.log('play error');
            }}
          />
        </div>

        <div className="block-img-Maladi" style={{ width: '30%' }}>
          <Button style={{padding:0}} variant="" onClick={() => setModalShow(true)}>
            <Image src={dataPostDetail?.file_mp3[0]?.image} className="img-responsive w-100 img-lyric" />
          </Button>
          <ImageModalDisplay
            show={modalShow}
            onHide={() => setModalShow(false)}
            ImageLyric={dataPostDetail?.file_mp3[0]?.image}
          />
        </div>
      </div>
    </div>
  );
};

export default PlayerApp;
