export const getCookie = (name) => {
  const cookieValue = document.cookie
    .split('; ')
    .find(cookie => cookie.startsWith(`${name}=`));

  return cookieValue ? cookieValue.split('=')[1] : null;
};

export const initialState = {
  filters: {},
  
  language:{
    languageCode: getCookie('lang') || 'fr',
  },

  // Initial state for Royal Family
  royalFamily: {
    data: [],
    isLoading: false,
    error: null,
    currentPage: 1,
  },

  // Initial state for post data
  posts: {
    tags: {
      data: [],
      isLoading: false,
      error: null,
      currentPage: 1,
    },
    recentNews: {
      data: [],
      isLoading: false,
      error: null,
      currentPage: 1,
    },
    royalActivities: {
      data: [], // Ensure data is initialized as an empty array
      isLoading: false,
      error: null,
      currentPage: 1,
    },
    correspondence: {
      data: [],
      isLoading: false,
      error: null,
      currentPage: 1,
    },
    souvenirPhotos: {
      data: [],
      isLoading: false,
      error: null,
      currentPage: 1,
    },
    history: {
      data: [],
      isLoading: false,
      error: null,
      currentPage: 1,
    },
    media: {
      data: [],
      isLoading: false,
      error: null,
      currentPage: 1,
    },
    documents: {
      data: [],
      isLoading: false,
      error: null,
      currentPage: 1,
    },
    messages: {
      data: [],
      isLoading: false,
      error: null,
      currentPage: 1,
    }
  },


  // Initial state for post search data
  searchPosts: {
    data: [],
    isLoading: false,
    error: null,
    currentPage: 1,
  },

  // Initial state for BMD data
  bmd: {
    data: [],
    isLoading: false,
    error: null,
    currentPage: 1,
  },
};