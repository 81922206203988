import React, { useState, useEffect } from "react";
import "../assets/styles/main.css";
import "../assets/styles/mobile.css";
import {
  Container,
  Nav,
  Navbar,
  NavDropdown,
  Form,
  Row,
  Col,
  Button,
} from "react-bootstrap";
import CarouselPhotoSlider from "./carousel";
import CarouselFlagSlider from "./carousel";
import Navbars from "./navbars";
import axiosInstance from "../context/axios";
import { useTranslation } from "react-i18next";
import SkeletonSlide from "./skeletonSlide";

export default function Header() {
  const { t } = useTranslation();
  const [slideType, setSlideType] = useState([]);
  const [dataSlide, setDataSlide] = useState([]);
  const [slideLoading, setSlideLoading] = useState(true)

  const resultType = slideType.reduce((acc, type) => {
    const slidesOfType = dataSlide.filter((slide) => slide.type === type.id);
    acc.push({
      type: type.name,
      slides: slidesOfType,
    });
    return acc;
  }, []);

  const newData = slideType.map((resultItem) => {
    const matchedType = resultType.find(
      (resultType) => resultType.type === resultItem.name
    );

    if (matchedType) {
      return {
        type: matchedType.type,
        slides: matchedType.slides,
      };
    }

    return null;
  });

  // Filter out null values (entries without matching types)
  const filteredData = newData.filter((entry) => entry !== null);
  useEffect(() => {
    getDataSliderItems();
  }, []);



  var settingsItemsFlagSldier = {
    dots: false,
    infinite: true,
    speed: 1000,
    autoplaySpeed: 4000,
    autoplay: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    cssEase: "linear",
    arrows: false,
    variableWidth: false,
  };

  var settingsItemsPhotoSldier = {
    dots: false,
    infinite: true,
    speed: 1000,
    autoplaySpeed: 5000,
    autoplay: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    cssEase: "linear",
    arrows: false,
    variableWidth: false,
  };

  // const getSlidesTpye = async () => {
  //   try {
  //     await axiosInstance.get("/api/slide/type/").then((resResponse) => {
  //       setSlideType(resResponse.data.results);
  //     });
  //   } catch (error) {
  //     console.log(error);
  //   }
  // };

  const getDataSliderItems = async () => {
    try {
      await axiosInstance.get("/api/slide/slides/").then((resResponse) => {
        setDataSlide(resResponse.data.results);
        setSlideLoading(false);
      });
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div className="header">
      <div className="bg-image-header">
        <Container>
          <Row>
            <Col className="slide-pull-left" md={2}>
              <div id="carousel_flag">
                {dataSlide && dataSlide.map((res) => {
                  if (res.type === "Flag Slide") {
                    return (
                      slideLoading ? (
                        <SkeletonSlide height={80} width={115} hiddenElement={true} />
                      ) : (
                        <CarouselFlagSlider
                          key={res.type}
                          settings={settingsItemsFlagSldier}
                          items={res.images}
                          slideType={slideType}
                        />
                      )
                    );
                  }
                  return null;
                })}
              </div>
            </Col>
            <Col md={8} style={{ textAlign: '-webkit-center' }}>
              <h5 className="royal-head-title">
                {t('slogen')}
              </h5>

              <h5 className="royal-head-link-old-web">
                <a href="https://old-site.norodomsihanouk.info/" target="_blank">
                  {t('label_link_oldWeb')}
                </a>
              </h5>

            </Col>
            <Col className="slide-pull-right" md={2}>
              <div id="carousel_image_King">
                {dataSlide && dataSlide.map((res) => {
                  if (res.type === "Photo Slide") {
                    return (
                      slideLoading ? (
                        <SkeletonSlide height={100} width={155} hiddenElement={true} />
                      ) : (
                        <CarouselPhotoSlider
                          key={res.type}
                          settings={settingsItemsPhotoSldier}
                          items={res.images}
                          slideType={slideType}
                        />
                      )
                    );
                  }
                  return null;
                })}
              </div>
            </Col>
          </Row>
        </Container>
      </div>
      <Navbars />
    </div>
  );
}
