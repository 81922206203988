import React from "react";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import "../assets/styles/skeleton.css";

const SkeletonCard = ({countCols}) => {
  const mainClass = `card col-lg-${countCols} col-md-${countCols} image-block full-width image-block`
  return (
    <SkeletonTheme baseColor="#E3E3E3" highlightColor="#BDBDBD">
      <ul className="list">
        {Array(8)
          .fill()
          .map((item, index) => (
            <li className={mainClass} key={index}>
              <Skeleton height={100} />
              <h4 className="card-title">
                {/* <Skeleton height={15} width={20} /> */}
                <Skeleton height={15} width={100} />
              </h4>
              <p className="card-channel categories">
                <Skeleton height={15} width={'80%'} />
              </p>
              <div className="card-metrics description">
                <Skeleton height={15} width={`100%`} />
              </div>
            </li>
          ))}
      </ul>
    </SkeletonTheme>
  );
};

export default SkeletonCard;
