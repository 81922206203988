// translations.js
import translationsEn from './translations_en';
import translationsFr from './translations_fr';
import translationsKm from './translations_km';

const translations = {
  en: translationsEn,
  fr: translationsFr,
  km: translationsKm,
};

export default translations;
